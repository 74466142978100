import { BasicPage } from "../components/BasicPage";
import { useAuth } from "../hooks/useAuth";
import Settings from "@mui/icons-material/Settings";
import { createContext, useContext, useEffect, useState } from "react";
import FaqAccordion from "../components/FaqAccordion";

import LiveHelp from "@mui/icons-material/LiveHelp";

export const FAQPage = () => {

    return (
        <BasicPage title="FAQ" icon={<LiveHelp />} >
            <FaqAccordion />
        </BasicPage>

    )

};
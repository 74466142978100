import React, { useState } from 'react'
import {
    Container, Grid, TextField, Typography,
} from "@mui/material/";

import Mail from "@mui/icons-material/Mail";

import { BasicPage } from '../components/BasicPage';


import { FormContact } from '../components/FormFields';

export const Contact = () => {


    return (
        <BasicPage title="Kontakt" icon={<Mail />}>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >
                <FormContact template="contact"></FormContact>

            </Grid>
        </BasicPage>
    );

}


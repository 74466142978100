import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import { router } from "./App";
import { deDE } from '@mui/material/locale';
import { ConfirmProvider } from "material-ui-confirm";
import firebaseConfig from "./config/firebaseConfig";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
var theme;
if (firebaseConfig.projectId == "litport-heine-c2471") {
    console.log("Firebase Config:", firebaseConfig)

    theme = createTheme({
        palette: {
            primary: { main: "#C60000" },
            text: {

            },
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        color: "#ffffff",
                        '& .MuiButton-root': {
                            color: "#ffffff",
                        },
                        '& .MuiIconButton-root': {
                            color: "#ffffff",
                        },

                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        // color: "#ffffff",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        // color: "#ffffff",
                    },
                },
            },
        },
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {

            },
            button: {
                fontSize: 14,

            },
        },
    },
        deDE,
    );
} else {
    console.log("Firebase Config not found")

    theme = createTheme({
        palette: {
            primary: { main: "#A5C500" }
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        color: "#ffffff",
                        '& .MuiButton-root': {
                            color: "#000000",
                        },
                        '& .MuiIconButton-root': {
                            color: "#000000",
                        },

                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: "#000000",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "#000000",
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: "#000000",
                    },
                },
            },

        },

        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {

            },
            button: {
                fontSize: 14,

            },
        },
    },
        deDE,
    )
}


// console.log("Process Env:", process.env)

root.render(
    // <StrictMode>
    <ThemeProvider theme={theme}>
        <ConfirmProvider>
            <RouterProvider router={router} />
        </ConfirmProvider>
    </ThemeProvider>
    // </StrictMode>
);


// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

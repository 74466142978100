import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../firebase";
import { ResultViewElastic } from "../components/ResultView";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import Select, { components } from "react-select";

import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
    buildAutocompleteQueryConfig,
    buildFacetConfigFromConfig,
    buildSearchOptionsFromConfig,
    buildSortOptionsFromConfig,
    getConfig,
    getFacetFields,
    stripUnnecessaryResultFields
} from "../config/configHelperMeta";
import {
    Container, Grid, Box, Typography, TextField, LinearProgress, Button
} from '@mui/material/';


const connector = new AppSearchAPIConnector({
    "engineName": "newbooks",
    "endpointBase": "https://olitport.ent.europe-west3.gcp.cloud.es.io",
    "searchKey": "search-4ogb2fbc3bhkkjn9k4vwhxk8",
});

const config = {
    debug: true,
    searchQuery: {
        // filters: [{}],
        facets: {
            subjectnb: {
                "type": "value",
                "size": 250
            }
        }
        ,
        // disjunctiveFacets: ["states"], 
        // disjunctiveFacetsAnalyticsTags: ["Ignore"],
        search_fields: {
            title: {},
            isbn: {},
        },
        result_fields: {
            title: {
                snippet: {
                    size: 100,
                    fallback: true
                },
                raw: {}
            },
            isbn: {
                snippet: {
                    size: 100,
                    fallback: true
                },
                raw: {}
            },
            publishingdate: {
                snippet: {
                    size: 100,
                    fallback: true
                },
                raw: {}
            },
            fn: {
                snippet: {
                    size: 100,
                    fallback: true
                },
                raw: {}
            },
            dt: {
                snippet: {
                    size: 100,
                    fallback: true
                },
                raw: {}
            }

        },
    },

    apiConnector: connector,
    alwaysSearchOnInitialLoad: true,
};


export default function SearchNewbooks({ handleClick }) {
    const [user, loading, error] = useAuthState(auth);

    return (

        <SearchProvider config={config}>
            <WithSearch
                mapContextToProps={({ wasSearched, isLoading }) => ({ wasSearched, isLoading })}
            >
                {({ wasSearched, isLoading }) => {

                    return (
                        <>
                            <ErrorBoundary>
                                <Layout
                                    header={
                                        <>
                                            <SearchBox
                                                autocompleteSuggestions={true}
                                                inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                                                    <>
                                                        <div className="sui-search-box__wrapper">
                                                            <TextField
                                                                label="Suche nach Titel, Autor, ISBN, ..."
                                                                {...getInputProps({
                                                                    placeholder: "",

                                                                })}
                                                            />
                                                            {getAutocomplete()}
                                                            {isLoading && <LinearProgress />}
                                                        </div>

                                                        {/* <Button
                                                        // {...getButtonProps({
                                                        //     "data-custom-attr": "some value",
                                                        //     "className": "" 
                                                        // })}
                                                        >Suchen</Button> */}
                                                    </>
                                                )}
                                            />

                                        </>

                                    }
                                    sideContent={
                                        <>
                                            {/* {wasSearched && !isLoading && */}
                                            {wasSearched &&
                                                <>
                                                    <Sorting
                                                        label={"Sortieren nach"}
                                                        sortOptions={buildSortOptionsFromConfig()}
                                                    />

                                                    {[{ field: "subjectnb", label: "Kategorie", showSearch: true }].map(n => (

                                                        <Facet
                                                            key={n.field}
                                                            field={n.field}
                                                            label={n.label}
                                                            showSearch={n.showSearch}
                                                            filterType="any"

                                                            viewx={({
                                                                label,
                                                                onMoreClick,
                                                                onRemove,
                                                                onSelect,
                                                                options,
                                                                showMore,
                                                                showSearch,
                                                                onSearch,
                                                                searchPlaceholder
                                                            }) => (
                                                                <fieldset class="sui-facet">
                                                                    <legend class="sui-facet__title">
                                                                        {label}
                                                                    </legend>

                                                                    {showSearch && (
                                                                        <div class="sui-facet__search">
                                                                            <input
                                                                                type="text"
                                                                                placeholder={searchPlaceholder}
                                                                                onChange={e => onSearch(e.target.value)}
                                                                            />
                                                                        </div>)
                                                                    }

                                                                    <div class="sui-multi-checkbox-facet">

                                                                        {options.map(option => (

                                                                            <label
                                                                                key={`${(option.value)}`}
                                                                                htmlFor={`example_facet_${label}${(
                                                                                    option.value
                                                                                )}`}
                                                                                className="sui-multi-checkbox-facet__option-label"
                                                                            >
                                                                                <div class="sui-multi-checkbox-facet__option-input-wrapper">
                                                                                    <input
                                                                                        data-transaction-name={`facet - ${label}`}
                                                                                        id={`example_facet_${label}${(
                                                                                            option.value
                                                                                        )}`}
                                                                                        type="checkbox"
                                                                                        className="sui-multi-checkbox-facet__checkbox"
                                                                                        checked={option.selected}
                                                                                        onChange={() => (option.selected ? onRemove(option.value) : onSelect(option.value))}
                                                                                    /><span className="sui-multi-checkbox-facet__input-text">{langMap[option.value] ? langMap[option.value] : option.value}</span>
                                                                                </div><span className="sui-multi-checkbox-facet__option-count">{option.count.toLocaleString("en")}
                                                                                </span>

                                                                            </label>

                                                                        ))}
                                                                    </div>
                                                                    {showMore && (
                                                                        <button
                                                                            type="button"
                                                                            className="sui-facet-view-more"
                                                                            onClick={onMoreClick}
                                                                            aria-label="Show more options"
                                                                        >
                                                                            + mehr
                                                                        </button>
                                                                    )}
                                                                </fieldset>
                                                            )}
                                                        />
                                                    ))}
                                                </>
                                            }
                                        </>
                                    }

                                    bodyContent={
                                        <>
                                            {!isLoading &&
                                                <Results
                                                    titleField={getConfig().titleField}
                                                    urlField={getConfig().urlField}
                                                    thumbnailField={getConfig().thumbnailField}
                                                    shouldTrackClickThrough={true}
                                                    resultViewx={ResultViewElastic}
                                                />}
                                        </>
                                    }

                                    bodyHeader={
                                        <React.Fragment>

                                            {wasSearched && !isLoading &&
                                                <PagingInfo

                                                    view={({
                                                        className,
                                                        end,
                                                        searchTerm,
                                                        start,
                                                        totalResults,
                                                        ...rest
                                                    }) => (
                                                        <div className={className} {...rest}>
                                                            Ergebnisse {" "}
                                                            <strong>
                                                                {start} - {end}
                                                            </strong>{" "}
                                                            von <strong>{totalResults}</strong>
                                                            {searchTerm && (
                                                                <>
                                                                    {" "}
                                                                    für: <em>{searchTerm}</em>
                                                                </>
                                                            )}
                                                        </div>

                                                    )}

                                                />}
                                            {wasSearched && !isLoading &&
                                                <ResultsPerPage


                                                    viewx={({
                                                        className,
                                                        onChange,
                                                        options,
                                                        selectedOption,
                                                        wrapOption,
                                                        selectedValue,
                                                        ...rest
                                                    }) => (

                                                        <div className={className} {...rest}>
                                                            <label htmlFor="results-per-page">Ergebnisse pro Seite</label>
                                                            <select

                                                                id="results-per-page"
                                                                onChange={e => onChange(e.target.value)}
                                                                value={selectedValue}
                                                            >
                                                                {options.map(option => (
                                                                    <option
                                                                        key={option}
                                                                        value={option}
                                                                    >
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>


                                                    )}

                                                />}
                                        </React.Fragment>
                                    }

                                    bodyFooter={
                                        <>
                                            {!isLoading && <Paging />}
                                        </>
                                    }
                                />
                            </ErrorBoundary>
                        </>
                    );
                }}
            </WithSearch>
        </SearchProvider>

    );
}

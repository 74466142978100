import React from 'react';
import firebaseConfig from '../config/firebaseConfig';

function Logo() {
    let imgSrc = firebaseConfig.imgSrc ? firebaseConfig.imgSrc : 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"%3E%3C/svg%3E';
    let imgWidth = firebaseConfig.imgWidth ? firebaseConfig.imgWidth : "0px";

    return (
        <img src={imgSrc} alt="Logo" width={imgWidth} height="auto" />
    );
}

export default Logo;
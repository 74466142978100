import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import { ErrorBoundary } from "react-error-boundary";

export const BasicPage = (props) => {

    function fallbackRender({ error, resetErrorBoundary }) {
        console.log(error);
        return (
            <Alert severity="error">{error.message ? error.message : error}</Alert>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <CssBaseline />

                <Container component="main" sx={{ mt: 1, mb: 10 }} maxWidth="xl">

                    <Box
                        sx={{
                            marginTop: 2,
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>{props.icon}</Avatar>
                        <Typography component="h1" variant="h4">
                            {props.title}
                        </Typography>
                    </Box>

                    <ErrorBoundary fallbackRender={fallbackRender}>
                        {props.children}
                    </ErrorBoundary>

                </Container>

                <Box
                    component="footer"
                    sx={{
                        py: 2,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[800],
                    }}
                >
                    <Container maxWidth="md">

                        <Typography variant="body2" color="text.secondary" align="center">
                            {'Copyright © '}
                            <Link color="inherit" href="">
                                Litport v2
                            </Link>{' '}
                            {new Date().getFullYear()}
                        </Typography>
                    </Container>

                </Box>
            </Box>
        </>
    );
};

import React, { Component, useState, useEffect } from 'react'
import { Container, Grid, Typography, Box, Avatar, Alert, Button, TextField, Link } from "@mui/material/";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../components/Copyright';
import CssBaseline from '@mui/material/CssBaseline';

import { auth, translateFirebaseError } from "../firebase";

const theme = createTheme();

export const LoginPage = () => {
    const { login } = useAuth();
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setError("");
        try {
            await login(data.get("email"), data.get("password"));
            // navigate("/dashboard/profile", { replace: true });
        } catch (err) {
            console.log("Error:")
            console.log(err);


            setError(translateFirebaseError(err));
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(/img/login.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Bei Litport anmelden
                    </Typography>
                    {error && <div variant="danger">{error}</div>}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {/* 
                        <TextField
                            required
                            fullWidth
                            id="accountID"
                            label="Kundennummer"
                            name="accountID"
                            autoComplete=""
                        /> */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Anmelden
                        </Button>
                        {error && <Alert severity="error">{error}</Alert>}

                        {/* <Grid container>
                            <Grid item>

                                <Link component={RouterLink} to="/register" variant="body2">
                                    {"Noch kein Account? Registrieren"}
                                </Link>

                            </Grid>
                        </Grid> */}

                    </Box>
                </Box>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
        </Grid>
    );
};

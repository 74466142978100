import React, { Component, useState, useEffect } from 'react'
import { BasicPage } from "../components/BasicPage";
import Settings from "@mui/icons-material/Settings";
import { Context } from "../context/appContext";

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button, Stack, Typography, ButtonGroup, Box, Alert
} from '@mui/material/';

import ShoppingCart from "@mui/icons-material/ShoppingCart";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import {
    FormContainer, TextFieldElement, PasswordRepeatElement,
    CheckboxElement, PasswordElement, SelectElement
} from 'react-hook-form-mui'

import { ButtonSubmit, FieldMessage, FieldName } from "../components/FormFields";
import { sendEmailTemplate, firebaseConfig } from "../firebase";
import { useAuth } from "../hooks/useAuth";

import { FieldsContact } from '../components/FormFields';
import { FieldsMagazine } from '../components/FormFields';


export const Cart = () => {
    const { state } = React.useContext(Context);
    const { dispatch } = React.useContext(Context);
    const { user, claims, accountID } = useAuth();
    const { cart } = state;

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    // const numberFormatx = (value) =>
    //     new Intl.NumberFormat('de-DE', {
    //         style: 'currency',
    //         currency: 'EUR'
    //     }).format(value);

    console.log(firebaseConfig);
    function numberFormat(value) {
        return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
    }

    function ccyFormat(num) {
        return `${num.toFixed(2)}`;
    }

    function priceRow(qty, unit) {
        return qty * unit;
    }

    const invoiceTotal = function (items) {

        var sum = 0;
        for (const [key, item] of Object.entries(items)) {
            sum = sum + item.price * item.qty;
        };
        return sum;
    }

    const invoiceTaxes7 = function (items) {
        var sum = 0;
        for (const [key, item] of Object.entries(items)) {
            var tax = parseInt(item.tax);
            if (tax === 7) {
                sum = sum + item.price * item.qty;

            }
        };
        return sum - sum / 1.07;
    }

    const invoiceTaxes19 = function (items) {
        var sum = 0;
        for (const [key, item] of Object.entries(items)) {
            var tax = parseInt(item.tax);
            if (tax === 19) {
                sum = sum + item.price * item.qty;
            }
        };
        return sum - sum / 1.19;
    }

    const hasMagazine = function (items) {
        for (const [key, item] of Object.entries(items)) {
            console.log(item);
            if (item.price == 0)
                return true;
        };
        return false;
    };


    const invoiceSubTotal = invoiceTotal(cart) - invoiceTaxes7(cart) - invoiceTaxes19(cart)


    const onSubmit = (formData) => {
        var data = {};

        data.cart = {};
        data.form = {};
        data.user = {};

        if (!accountID) {
            setError("Bitte wählen Sie einen Account aus.");
            return;
        }

        let i = 1, cart2 = {};

        for (const [key, item] of Object.entries(cart)) {
            var tax = parseInt(item.tax);
            item.nettoPrice = item.price / (1 + tax / 100);
            cart2[i] = item;
            i++;
        };
        data.form.invoiceTotal = invoiceTotal(cart);
        data.form.invoiceTaxes7 = invoiceTaxes7(cart);
        data.form.invoiceTaxes19 = invoiceTaxes19(cart);
        data.form.invoiceSubTotal = invoiceSubTotal;
        data.form.accountID = accountID;
        data.form.orderDate = new Date().toLocaleDateString();
        data.form.orderTime = new Date().toLocaleTimeString();

        data.template = "orderCart";

        Object.assign(data.cart, cart2);
        Object.assign(data.form, formData);
        Object.assign(data.user, claims);

        sendEmailTemplate(data);
        setSuccess("Bestellung erfolgreich abgeschickt.");
        dispatch({ type: "CLEAR_CART" });
    }

    useEffect(() => {
        setError("");

    }, [accountID, cart]);

    return (

        < BasicPage title="Warenkorb" icon={<ShoppingCart />}>

            {cart && Object.keys(cart).length === 0 &&
                <Typography>Warenkorb ist leer</Typography>
            }

            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            {cart && Object.keys(cart).length > 0 && (
                <>

                    <TableContainer
                        component={Paper}
                        sx={{ marginTop: 3, marginBottom: 2 }}
                    >
                        <Table aria-label="spanning table">
                            <TableHead>
                                {/* <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                        Bestellungen
                                    </TableCell>
                                    <TableCell align="right">Preis</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>Titel</TableCell>
                                    <TableCell align="right">Anzahl</TableCell>
                                    <TableCell align="right">Einzelpreis</TableCell>
                                    <TableCell align="right">Steuer</TableCell>
                                    <TableCell align="right">Gesamtpreis</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(cart).map((item, i) => (

                                    <TableRow key={cart[item].isbn}>
                                        <TableCell>{cart[item].title} ({cart[item].isbn})</TableCell>
                                        <TableCell align="right">

                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Button onClick={() => dispatch({ type: "ADD_TO_CART", payload: cart[item] })}>+</Button>
                                                <Button disabled>{cart[item].qty}</Button>
                                                <Button onClick={() => dispatch({ type: "REMOVE_ONE_FROM_CART", payload: cart[item] })}>-</Button>
                                            </ButtonGroup>

                                        </TableCell>
                                        <TableCell align="right">{numberFormat((cart[item].price))}</TableCell>
                                        <TableCell align="right">{cart[item].tax} %</TableCell>
                                        <TableCell align="right">{numberFormat(priceRow(cart[item].qty, cart[item].price))}</TableCell>
                                        <TableCell align="right">
                                            <button onClick={() => dispatch({ type: "REMOVE_FROM_CART", payload: cart[item] })}>
                                                <DeleteIcon />
                                            </button>
                                        </TableCell>
                                    </TableRow>


                                ))}

                                <TableRow>

                                    <TableCell colSpan={2} rowSpan={3} />

                                    <TableCell >Nettopreis</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{numberFormat(invoiceSubTotal)}</TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell >Steuer</TableCell>

                                    <TableCell align="right">7 %</TableCell>
                                    <TableCell align="right">{numberFormat(invoiceTaxes7(cart))}</TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell>Steuer</TableCell>
                                    <TableCell align="right">19 %</TableCell>
                                    <TableCell align="right">{numberFormat(invoiceTaxes19(cart))}</TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell >Gesamtpreis</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{numberFormat(invoiceTotal(cart))}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>


                    <FormContainer
                        mt={2}
                        pt={2}
                        onSuccess={onSubmit}
                        // onSubmit={onSubmit}
                        FormProps={{
                            'aria-autocomplete': 'none',
                            autoComplete: 'new-password'
                        }}>

                        <Stack spacing={2}>

                            <FieldsContact></FieldsContact>
                            {hasMagazine && <FieldsMagazine></FieldsMagazine>}


                            {error && <Alert severity="error">{error}</Alert>}
                            {success && <Alert severity="success">{success}</Alert>}

                            <Stack direction="row" spacing={2}
                                justifyContent="space-between"
                            >

                                <Button
                                    variant="contained"
                                    type="reset"
                                    size="large"
                                    color="error"
                                    onClick={() => dispatch({ type: "CLEAR_CART" })}
                                >
                                    Warenkorbinhalt löschen
                                </Button>

                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"


                                >
                                    Bestellung abschicken
                                </Button>


                            </Stack>

                        </Stack>
                    </FormContainer>
                </>
            )
            }
        </BasicPage >
    );
};

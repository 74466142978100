import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { NavMain } from "./NavMain";

export const HomeLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();

    if (user) {
        return <Navigate to="/web/welcome" replace />;
    }

    return (
        <>
            {/* <AppBar
                pages={[
                    { label: "Home", path: "/" },
                    { label: "Login", path: "/login" }
                ]}
            /> */}
            {outlet}
        </>
    );
};

import React, { Fragment } from 'react';
import { BasicPage } from "../components/BasicPage";
import { useAuth, apiFetch } from "../hooks/useAuth";
import { createContext, useContext, useEffect, useState } from "react";

import {
    Card, Typography, CardContent, FormControl, FormLabel, RadioGroup, Grid, Box, Alert,
    LinearProgress, Container, Button, TextField, Radio, FormControlLabel, InputLabel, Select, MenuItem
} from "@mui/material/";

import axios from 'axios';


export default function SearchAI() {
    const { user, change, claims, accountID } = useAuth();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');

    const [messages, setMessages] = React.useState([
        { role: 'system', content: 'Der User sucht noch Informationen zu Büchern. Wenn du einen Buchtitel ausgibst dann in einem <strong> Tag. ISBN bitte nicht ausgeben. Und nur Bücher ausgeben, bei denen du sicher bist, dass sie existieren.' },
        { role: 'assistant', content: 'Hallo! Ich bin dein digitaler Bücherfreund! Wie kann ich dir helfen?' }
    ]);


    const chatContainerRef = React.useRef(null);
    const token = useAuth().token; // funktioniert nur so


    const handleQuery = async () => {
        // Add user message to the local messages array
        const newMessages = [...messages, { role: 'user', content: prompt }];

        try {
            setLoading(true);
            const result = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    messages: newMessages,
                    model: "gpt-4",
                    max_tokens: 350
                },
                {
                    headers: {
                        'Authorization': `Bearer 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`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Assuming the OpenAI API responds with a message object for the assistant's reply
            const assistantMessage = result.data.choices[0].message.content;

            // Add API response to the messages
            setMessages([...newMessages, { role: 'assistant', content: assistantMessage }]);

            // Clear the input
            setPrompt('');
            setLoading(false);
        } catch (error) {
            console.error("Error calling OpenAI API:", error);
        }
    };


    const handleQueryNoMessage = async () => {
        // Add user message
        setMessages([...messages, { text: prompt, sender: "user" }]);

        try {
            setLoading(true);
            const result = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                    prompt: "Du bist ein Bibliograph und informierst deine User über Bücher Inhalte, ISBN. " + prompt,
                    model: "gpt-3.5-turbo-instruct",
                    max_tokens: 350
                },
                {
                    headers: {
                        'Authorization': `Bearer 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`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // Add API response
            setMessages([...messages, { text: prompt, sender: "user" }, { text: result.data.choices[0].text, sender: "api" }]);

        } catch (error) {
            console.error("Error calling OpenAI API:", error);
        }
    };



    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            const scrollHeight = chatContainerRef.current.scrollHeight;
            const height = chatContainerRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            chatContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }

    function transformText(text) {
        const isbnPattern = /\b\d{10}\b|\b\d{3}-\d{1,5}-\d{1,7}-\d{1,7}-\d{1,7}\b|\b\d{3}-\d{10}\b/g;
        const transformedText = text.replace(isbnPattern, (match) => {
            return `<a target="_blank" href="/web/searchmeta?q=${match}">${match}</a>`;
        });

        const strongPattern = /<strong>([^<]+)<\/strong>/g;
        const transformedText2 = transformedText.replace(strongPattern, (match, capturedText) => {
            const linkUrl = `/web/searchmeta?q=${encodeURIComponent(capturedText)}`;
            return `<a target="_blank" href="${linkUrl}"><strong>${capturedText}</strong></a>`;
        });

        return transformedText2.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                <span dangerouslySetInnerHTML={{ __html: line }} />
                {index !== text.length - 1 && <br />}
            </React.Fragment>
        ));
    }

    useEffect(() => {
        setError("");
        setMessage("");

        if (!accountID) {
            setError("Es wurde keine AccountID ausgewaehlt");
            //return;
        }

        if (!token) {
            setError("Es gibt keinen definierten User Token");
            return
        }
        scrollToBottom();
        setPrompt('');
        setLoading(false);
        // setLoading(true);


    }, [messages]);

    return (

        <BasicPage title="Litpoet AI (Beta)" >

            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                mt={6}
            >
                {loading &&
                    <Box
                        mt={2}
                        mb={2}
                        sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
                < Grid item xs={12} >

                    <Container>

                        <div
                            ref={chatContainerRef}
                            style={{
                                maxHeight: '400px',
                                overflowY: 'auto',
                                border: '1px solid #ddd',
                                padding: '10px',
                                marginBottom: '20px'
                            }}>
                            {messages.map((message, index) => {
                                if (message.role === "system") return null;  // Don't render context messages

                                return (
                                    <div key={index} style={{
                                        textAlign: message.role === "assistant" ? "left" : "right",
                                        marginBottom: '10px'
                                    }}>
                                        <Typography variant="body1" style={{
                                            display: 'inline-block',
                                            padding: '8px',
                                            borderRadius: '10px',
                                            background: message.role === "assistant" ? '#e0e0e0' : '#0f75f2',
                                            color: message.role === "assistant" ? 'black' : 'white'
                                        }}>
                                            {message.role === "assistant" ? transformText(message.content) : message.content}
                                        </Typography>
                                    </div>
                                );
                            })}

                        </div>



                        <TextField
                            label="Prompt"
                            value={prompt}
                            onChange={e => setPrompt(e.target.value)}
                            fullWidth
                        />
                        <Button variant="contained" color="primary" onClick={handleQuery}>
                            Query OpenAI
                        </Button>
                    </Container>


                </Grid >



            </Grid>


        </BasicPage >
    )
};

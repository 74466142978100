import { Navigate, useOutlet, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { NavMain } from "./NavMain";
import { Provider } from "../context/appContext"
import NavTop from "./NavTop";

export const ProtectedLayout = () => {

    const { user } = useAuth();
    const outlet = useOutlet();

    if (!user) {
        return <Navigate to="/login" />;
    }

    return (

        <Provider>
            <NavTop></NavTop>
            <NavMain
                pages={[
                    // { label: "Schnellsuche", path: "searchlibriapi" },
                    // { label: "Profisuche", path: "searchvlbapi" },
                    { label: "Meta-Suche", path: "searchmeta" },
                    // { label: "Libri Suche", path: "searchlibri" },
                    // { label: "Zeitschriften Suche", path: "searchmagazines" },

                    { label: "Bestell-Status", path: "orderstatus" },
                    { label: "Abo-Status", path: "abonnements/abostatus" },
                    { label: "Bestell-Service", path: "orderservice" },
                ]}
                settings={[
                    { label: "Profil", path: "profile" },
                ]}
            />
            {outlet}
        </Provider>

    );
};
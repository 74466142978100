import React, { useEffect, useState } from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.litport.com/">
        Litport v2
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../firebase";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";
import {
    Container, Grid, Box, Typography, TextField, LinearProgress, Button
} from '@mui/material/';
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
    buildAutocompleteQueryConfig,
    buildFacetConfigFromConfig,
    buildSearchOptionsFromConfig,
    buildSortOptionsFromConfig,
    getConfig,
    getFacetFields
} from "../config/configHelperMagazines";

import { BasicPage } from "../components/BasicPage";
import { ResultViewElastic } from "../components/ResultView"

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const connector = new AppSearchAPIConnector({
    searchKey,
    engineName,
    hostIdentifier,
    endpointBase
});

const config = {
    searchQuery: {
        facets: buildFacetConfigFromConfig(),
        ...buildSearchOptionsFromConfig()
    },
    autocompleteQuery: buildAutocompleteQueryConfig(),
    apiConnector: connector,
    alwaysSearchOnInitialLoad: false
};



export default function SearchMagazines({ handleClick }) {
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const navigate = useNavigate();

    const { productCode, vendor, value } = useParams();

    const langMap = {
        "ger": "Deutsch",
        "eng": "Englisch",
        "fre": "Französisch",
        "ita": "Italienisch",
        "spa": "Spanisch",
        "por": "Portugiesisch",
        "rus": "Russisch",
        "pol": "Polnisch",
        "dut": "Niederländisch",
        "swe": "Schwedisch",
        "fin": "Finnisch",
        "dan": "Dänisch",
        "nor": "Norwegisch",
        "gre": "Griechisch",
        "tur": "Türkisch",
        "chi": "Chinesisch",
        "jpn": "Japanisch",
        "kor": "Koreanisch",
        "ara": "Arabisch",
        "heb": "Hebräisch",
        "hin": "Hindi",
        "tha": "Thailändisch",
        "vie": "Vietnamesisch",
        "ind": "Indonesisch",
        "per": "Persisch",
        "rum": "Rumänisch",
        "bul": "Bulgarisch",
        "cze": "Tschechisch",
        "slo": "Slowakisch",
        "hun": "Ungarisch",
        "est": "Estnisch",
        "lav": "Lettisch",
        "lit": "Litauisch",
        "slv": "Slowenisch",
        "mac": "Mazedonisch",
        "alb": "Albanisch",
        "arm": "Armenisch",
        "aze": "Aserbaidschanisch",
        "bel": "Weißrussisch",
        "bos": "Bosnisch",
        "geo": "Georgisch",
    }



    // useEffect(() => {
    //     if (loading) return;
    //     if (!user) return navigate("/");

    // }, [user, loading]);

    // if (!user) return (
    //     <></>
    // );

    return (
        <>
            {/* <BasicPage title="Elastic Suche Zeitschriften"> */}
            <SearchProvider config={config}>
                <WithSearch
                    mapContextToProps={({ wasSearched, isLoading }) => ({ wasSearched, isLoading })}
                >
                    {({ wasSearched, isLoading }) => {

                        return (
                            <>
                                <ErrorBoundary>

                                    <Layout
                                        header={
                                            <>
                                                <SearchBox
                                                    autocompleteSuggestions={true}
                                                    inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                                                        <>
                                                            <div className="sui-search-box__wrapper">
                                                                <TextField
                                                                    label="Suche nach Titel, ISBN, ..."
                                                                    {...getInputProps({
                                                                        placeholder: "",

                                                                    })}
                                                                />
                                                                {getAutocomplete()}
                                                                {isLoading && <LinearProgress />}
                                                            </div>

                                                            {/* <Button
                                                        // {...getButtonProps({
                                                        //     "data-custom-attr": "some value",
                                                        //     "className": "" 
                                                        // })}
                                                        >Suchen</Button> */}
                                                        </>
                                                    )}
                                                />

                                            </>

                                        }
                                        sideContent={
                                            <>
                                                {wasSearched && !isLoading &&
                                                    <>
                                                        <Sorting
                                                            label={"Sortieren nach"}
                                                            sortOptions={buildSortOptionsFromConfig()}
                                                        />

                                                        {getFacetFields().map(field => (
                                                            <Facet
                                                                key={field}
                                                                field={field}
                                                                label={field}
                                                                view={({
                                                                    label,
                                                                    onMoreClick,
                                                                    onRemove,
                                                                    onSelect,
                                                                    options,
                                                                    showMore,
                                                                    showSearch,
                                                                    onSearch,
                                                                    searchPlaceholder }) => (
                                                                    <fieldset class="sui-facet">
                                                                        <legend class="sui-facet__title">
                                                                            {label}
                                                                        </legend>


                                                                        <div class="sui-facet__search">
                                                                            <input
                                                                                type="text"
                                                                                placeholder={searchPlaceholder}
                                                                                onChange={e => onSearch(e.target.value)}
                                                                            />
                                                                        </div>


                                                                        <div class="sui-multi-checkbox-facet">

                                                                            {options.map(option => (


                                                                                <label
                                                                                    key={`${(option.value)}`}
                                                                                    htmlFor={`example_facet_${label}${(
                                                                                        option.value
                                                                                    )}`}
                                                                                    className="sui-multi-checkbox-facet__option-label"
                                                                                >
                                                                                    <div class="sui-multi-checkbox-facet__option-input-wrapper">
                                                                                        <input
                                                                                            data-transaction-name={`facet - ${label}`}
                                                                                            id={`example_facet_${label}${(
                                                                                                option.value
                                                                                            )}`}
                                                                                            type="checkbox"
                                                                                            className="sui-multi-checkbox-facet__checkbox"
                                                                                            checked={option.selected}
                                                                                            onChange={() => (option.selected ? onRemove(option.value) : onSelect(option.value))}
                                                                                        /><span className="sui-multi-checkbox-facet__input-text">{langMap[option.value] ? langMap[option.value] : option.value}</span>
                                                                                    </div><span className="sui-multi-checkbox-facet__option-count">{option.count.toLocaleString("en")}
                                                                                    </span>

                                                                                </label>

                                                                            ))}
                                                                        </div>
                                                                        {showMore && (
                                                                            <button
                                                                                type="button"
                                                                                className="sui-facet-view-more"
                                                                                onClick={onMoreClick}
                                                                                aria-label="Show more options"
                                                                            >
                                                                                + mehr
                                                                            </button>
                                                                        )}
                                                                    </fieldset>
                                                                )}
                                                            />
                                                        ))}
                                                    </>
                                                }
                                            </>
                                        }

                                        bodyContent={
                                            <>
                                                {!isLoading &&
                                                    <Results
                                                        titleField={getConfig().titleField}
                                                        urlField={getConfig().urlField}
                                                        thumbnailField={getConfig().thumbnailField}
                                                        shouldTrackClickThrough={true}
                                                        resultView={ResultViewElastic}
                                                    />}
                                            </>
                                        }

                                        bodyHeader={
                                            <React.Fragment>

                                                {wasSearched && !isLoading && <PagingInfo />}
                                                {wasSearched && !isLoading && <ResultsPerPage />}
                                            </React.Fragment>
                                        }

                                        bodyFooter={
                                            <>
                                                {!isLoading && <Paging />}
                                            </>
                                        }
                                    />
                                </ErrorBoundary>
                            </>
                        );
                    }}
                </WithSearch>
            </SearchProvider>
            {/* </BasicPage> */}

        </>
    );
}

const firebaseConfig = {
    apiKey: "AIzaSyBI4ICw9fGt4ry8YIXjVaIk_tkj3iJbqi0",
    authDomain: "litport-web-85e85.firebaseapp.com",
    projectId: "litport-web",
    storageBucket: "litport-web.appspot.com",
    messagingSenderId: "806952225695",
    appId: "1:806952225695:web:df0a27f5acb416d8548baa",
    imgSrc: "/img/brunswikerweb.jpg",
    imgWidth: "400px",
    imprintLink: "https://brunswiker.de/impressum/",
    email: ["rkeser@collectivum.io", "John.harms@brunswiker.de", "service@brunswiker.de"]
};

export default firebaseConfig;
import { Suspense } from "react";
import { useLoaderData, useOutlet, Await, useAsyncError } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import { AuthProvider } from "../hooks/useAuth";

export const AuthLayout = () => {
    const outlet = useOutlet();

    const { userPromise } = useLoaderData();

    function ReviewsError() {
        const error = useAsyncError();
        return (
            <>
                <Alert severity="error">Ein Fehler ist aufgetreten: <b>{error.message}</b>. Bitte die Seite neu laden.</Alert>
            </>
        )
    }

    return (
        <Suspense fallback={<LinearProgress />}>
            <Await
                resolve={userPromise}
                errorElement={<ReviewsError />}

                children={(user) => (
                    <AuthProvider userData={user}>
                        {outlet}
                    </AuthProvider>
                )}
            />
        </Suspense>
    );
};

import { initializeApp } from "firebase/app";

import {
    GoogleAuthProvider,
    getAuth,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";

import {
    getFirestore,
    query,
    getDocs,
    getDoc,
    collection,
    where,
    addDoc,
    setDoc,
    doc,
} from "firebase/firestore";
import {
    getStorage,
} from "firebase/storage"

import emailTemplates from "./emailTemplates";
import firebaseConfig from "./config/firebaseConfig";


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

// translate firebase error codes to human readable messages in german

const translateFirebaseError = (error) => {
    switch (error.code) {
        case "auth/invalid-email":
            return "Ungültige E-Mail-Adresse";
        case "auth/user-disabled":
            return "UUID ist deaktiviert";
        case "auth/user-not-found":
            return "UUID nicht gefunden";
        case "auth/wrong-password":
            return "Falsches Passwort";
        case "auth/email-already-in-use":
            return "E-Mail-Adresse ist bereits registriert";
        case "auth/weak-password":
            return "Passwort ist zu schwach - bitte mindestens 6 Zeichen";
        case "auth/operation-not-allowed":
            return "Operation nicht erlaubt";
        case "auth/too-many-requests":
            return "Zu viele Anfragen. Bitte versuchen Sie es später erneut";
        case "auth/requires-recent-login":
            return "Zu viele Anfragen. Bitte versuchen Sie es später erneut";

        default:
            return error.message;
    }
};

const setEmailTemplates = async () => {

    for (const category of emailTemplates) {
        const template = {
            subject: category.subject,
            html: category.html
        };

        const docRef = doc(db, "templates", category.name);
        const docSnap = await getDoc(docRef);
        await setDoc(docRef, template);
        if (!docSnap.exists()) {
            await setDoc(docRef, template);
        }
    }
};

const numberFormat = (value) =>
    new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
    }).format(value);

const formatPrice = (obj) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined) {
            obj[key] = "nicht angegeben";
        }
        if (key === "price" || key === "Einzelpreis" || key === "nettoPrice" || key === "invoiceTotal" || key === "invoiceTaxes7" || key === "invoiceTaxes19" || key === "invoiceSubTotal") {
            obj[key] = obj[key] ? obj[key].toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) : obj[key];
        } else if (typeof obj[key] === "object") {
            formatPrice(obj[key]);
        }
    });
};

const sendEmailTemplate = async (data) => {
    // setEmailTemplates();
    console.log("--Email--")
    let _data = JSON.parse(JSON.stringify(data));

    formatPrice(_data);

    console.log(_data);

    await addDoc(collection(db, "mail"), {
        to: firebaseConfig.email,
        template: {
            name: _data.template || "debug",
            data: _data,
        },
    });

    await addDoc(collection(db, "mail"), {
        to: _data.user.email,
        template: {
            name: _data.template,
            data: _data,
        },
    });

};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    sendPasswordReset,
    logout,

    sendEmailTemplate,
    translateFirebaseError,
    firebaseConfig,
};

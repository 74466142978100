import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));



const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({



  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    fontSize: '12px',

  },
  '&.Mui-expanded': {
    fontWeight: 'bold',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FaqAccordion() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Grundsätzliches</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Der Litport wird als gemeinsames Projekt der führenden, inhabergeführten Fachinformationshändlern Deutschlands entwickelt und steht noch in der Anfangsphase der Entwicklungen. Viele neue Features, Verbesserungen und Erweiterungen sind in Planung und sollen in Zukunft umgesetzt werden, um Ihnen und unseren Mitarbeitenden ein Hilfsmittel zur Verfügung zu stellen, was den Spagat zwischen Digitalisierung und persönlicher Betreuung schafft.
            <br />
            Wir freuen uns über Feedback, Anregungen und auch Fehlermeldungen, gerne persönlich oder über das Kontaktformular.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* 
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Dashboard / Startseite</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Auf dem Dashboard, welches Sie als Startseite und jederzeit über einen Klick auf das Logo erreichen können, finden Sie Kennzahlen über ihre Aufträge, welche Sie im aktuellen Jahr bei uns platziert haben. Die sind wie folgt zu interpretieren:
            <br />
            <br />
            <ul>
              <li>Käufe zeigt Ihnen die Anzahl der Positionen Apartbestellungen (Bücher), d.h. die Summe der Einzeltitel. Einfach gesagt: Wie viele Bücher Sie bei uns in Auftrag gegeben haben.</li>
              <li>Umsatz zeigt die Summe des Umsatzes im aktuellen Jahr über alle Artikeltypen (Bücher, Zeitschriften, Fortsetzungen)</li>

              <li>Abos zeigt Ihnen die Anzahl der aktiven Abonnements im Bereich Zeitschriften und Fortsetzungen, welche Sie im aktuellen Jahr bei uns platziert haben.</li>
            </ul>
            <br />


            Zukünftig geplante Entwicklungen:

            <ul>
              <li>Weitere Kennzahlen hinzufügen</li>
              <li>Weitere Darstellungen, z.B. Umsatz pro Monat in einem Balkendiagramm oder Graphen</li>
              <li>Filtermöglichkeiten, z.B. nach Zeit</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion> */}

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Meta-Suche </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Die Meta-Suche erlaubt eine Suche über mehrere Kataloge hinweg. Zurzeit sind im Litport 3 Kataloge hinterlegt, in denen gesucht werden kann. Die Suchergebnisse können verfeinert werden, indem der Suchbegriff bzw. die Suchbegriffe ergänzt werden, beispielsweise durch den Autor oder den Verlag.
            <br /> <br />
            Die Suchergebnisse selbst können über den Bereich links weiter verfeinert werden, in dem die Haken entfernt werden. Ebenso ist es möglich, innerhalb der Kategorien zu suchen, um schneller zum Ergebnis zu kommen.
            <br /> <br />
            Nachdem der gewünschte Artikel gefunden wurde, kann dieser in den Warenkorb gelegt oder die Merkliste gesetzt werden. Beide werden browserübergreifend gespeichert, sodass Sie von jedem Endgerät darauf zugreifen können.
            <br /> <br />
            Zukünftig geplante Entwicklungen:
            <ul>
              <li>Anschluss weiterer Kataloge</li>
              <li>Verbesserung der Meta Daten und Erhöhung der Datenqualität</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3a'} onChange={handleChange('panel3a')}>
        <AccordionSummary aria-controls="panel3ad-content" id="panel3ad-header">
          <Typography>Bestell-Status  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Im Bestell-Status finden sich alle Bestellpositionen von Einzelbestellungen (Büchern) wieder, welche Sie bei uns platziert haben.
            <br /> <br />
            Ebenso finden Sie den Status der Bestellung in Form einer Ampel, sodass Sie immer einen Überblick über die noch nicht gelieferten Bestellungen erhalten.
            <br /> <br />
            Sie haben die Möglichkeit, auf die Anzeige der Bestellungen einzuwirken.
            <br /> <br />
            Die Schaltfläche „Spalten“ und „Zeilenhöhe“ verändern die Darstellung der Sucherergebnisse.
            <br /> <br />
            Die unten im Screenshot markierten Funktionen bedürfen möglicherweise einer Erklärung, daher haben wir diese hier markiert.
            <br /> <br />
            Die rote Box „Filter“ erlaubt Ihnen das Setzen eines Filters, sodass nach Werten in einer von Ihnen bestimmten Spalte gesucht werden kann.
            <br /> <br />
            Die blaue Box „Exportieren“ erlaubt einen Export der zurzeit angezeigten Dateien in ein .csv-Format, welches von Programmen wie Excel genutzt werden kann.
            <br /> <br />
            Das Symbol „Briefumschlag“ erlaubt eine Reklamation direkt aus dem System heraus an ihre Ansprechperson, sollten Sie Fragen zu dieser Bestellposition haben.
            <br /> <br />
            Das Symbol „Wolke“ erlaubt den Download von Rechnungen, Lieferscheinen und Gutschriften. Nur bei Brunswiker Mandatn!!! Bitte beachten Sie, dass durch eine Systemumstellung keine Dokumente vor März 2022 angezeigt werden können.
            <br /> <br />
            <img style={{ maxWidth: '1200px' }} src="/img/faq_bestellungen.png" alt="FAQ Bestellungen" />
            <br /> <br />
            Geplante Verbesserungen:

            <ul>
              <li>Ermöglichen von Zeitfilterung sowie der Nutzung von mehr als einem Filter</li>
              <li>Anzeige der Meldenummern seitens unserer Lieferanten, sodass der Status der offenen Bestellungen ersichtlich ist.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Abo-Status </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Der Abo-Status zeigt Ihnen die aktiven Abonnements von Fortsetzungen und Zeitschriften an.
            <br /> <br />
            Im Grunde funktioniert die Anzeige wie der Bestell-Status, zusätzlich kann auf dem Symbol „Pfeil“ der Verteiler angezeigt werden, falls dieser vorhanden ist.

            <br /> <br />

            Geplante Verbesserungen:
            <br /> <br />
            <ul><li>siehe Bestell-Status</li></ul>

          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Bestell-Service  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Über dieses Formular können Sie bei uns Bestellungen aufgeben. Dies ist besonders hilfreich, wenn Sie einen Artikel suchen, der nicht in unseren Katalogen zu finden ist.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography>User + Passwort ändern   </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mit dem Klick auf das Symbol „Person“ oben rechts haben Sie die Möglichkeit, die für Sie hinterlegten Informationen anzusehen und Ihr Passwort zu ändern.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography>Admin  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Als Admin haben Sie die Möglichkeit, weitere User für die Kundennummer(n) anzulegen, für welche Sie durch uns freigegeben wurden. Klicken Sie hierfür auf die Option „User anzeigen“.        </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
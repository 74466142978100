import React, { Component, useState, useEffect } from 'react'

import {
    Container, Typography, Checkbox, CssBaseline, Avatar,
    Grid, Box, Alert, Link, TextField, Button, FormControl, InputLabel, Select, MenuItem
} from '@mui/material/';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../components/Copyright';
import { useAuth, apiFetch } from "../hooks/useAuth";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import { auth, translateFirebaseError } from "../firebase";

const theme = createTheme();


export default function SignUp() {
    const { signup, login, logout } = useAuth();
    const [error, setError] = useState("");
    const token = useAuth().token; // funktioniert nur so

    const patchClaims = async (uid, displayName, email, password, role, accountid, company, tenant) => {

        return apiFetch('/app/users/' + uid, {
            method: "PATCH",
            body: JSON.stringify({
                "displayName": displayName,
                "email": email,
                "password": password,
                "role": role,
                "accountid": accountid,
                "company": company,
                "tenant": tenant
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);

                if (data.code) {
                    setError(data.message);
                }
            })
            .catch(error => {
                console.log(error);
                setError(translateFirebaseError(error));
            });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setError("");

        try {

            const res = await signup(
                data.get("email"),
                data.get("password"),
            );
            await logout();

            console.log("register", res);

            if (res && res.user.uid) {
                console.log(res.user.uid, data.get("firstName") + " " + data.get("lastName"),
                    data.get("email"), data.get("password"), data.get("role"), data.get("accountid"),
                    data.get("company"), data.get("tenant"));

                const resPatch = await patchClaims(res.user.uid, data.get("firstName") + " " + data.get("lastName"),
                    data.get("email"), data.get("password"), data.get("role"), data.get("accountid"),
                    data.get("company"), data.get("tenant")
                );

                await login(data.get("email"), data.get("password"));
            }


        } catch (err) {
            setError(translateFirebaseError(err));
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        Bei Litport registrieren
                    </Typography>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="tenant"
                                    label="Mandant"
                                    name="tenant"
                                    autoComplete=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="company"
                                    label="Company"
                                    name="company"
                                    autoComplete=""
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="Vorname"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Nachname"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Adresse"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="accountid"
                                    label="Kundennummer"
                                    name="accountid"
                                    autoComplete=""
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="userrole-select-label">User Role</InputLabel>
                                    <Select
                                        labelId="userrole-select-label"
                                        id="role"
                                        name="role"

                                        label="User Role"
                                    // onChange={(e) => setRole(e.target.value)}
                                    >
                                        <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                                        <MenuItem value="Admin">Admin</MenuItem>
                                        <MenuItem value="User">User</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid> */}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Registrieren
                        </Button>

                        {error && <Alert severity="error">{error}</Alert>}

                        <Grid container justifyContent="flex-end">
                            <Grid item>

                                <Link component={RouterLink} to="/" variant="body2">
                                    Hast du schon ein Account? Anmelden
                                </Link>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}
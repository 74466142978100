const emailTemplates = [
    {
        name: 'debug',
        subject: 'Litport Debug',
        html: `
        <h2>Formular Data</h2>
        <ul>
          {{#each form}}
            <li><b>{{@key}}</b> {{this}}</li>
          {{/each}}
        </ul>
        <h2>User Data</h2>
        <ul>
          {{#each user}}
            <li><b>{{@key}}</b> {{this}}</li>
          {{/each}}
        </ul>
        <h2>Warenkorb Data</h2>
        <ul>
          {{#each cart}}
            <li><b>{{@key}}</b>
              <ul>
                {{#each this}}
                  <li>{{@key}}: {{this}}</li>
                {{/each}}
              </ul>
            </li>
          {{/each}}
        </ul>`
    },
    {
        name: 'contact',
        subject: '[Kontaktanfrage] von {{user.cny}}, {{user.name}} ({{form.accountID}})',
        html: `
        -------------------------------------------------------------------------------
        <br> Kontaktanfrage:<br> <br> Von: {{user.cny}}, {{user.name}} ({{form.accountID}})
        <br> Email: {{user.email}}<br>  <br> Korrespondenz:<br> {{form.message}}<br> <br>
        <br> -------------------------------------------------------------------------------
        <br>`
    },
    {
        name: 'order',
        subject: "Buchbestellung von {{user.cny}}, {{user.name}}",
        html: `
        Bestellbestätigung<br> 
        ------------------<br> <br> Bestellt am: {{form.orderDate}} um {{form.orderTime}}
        <br> Von: {{user.cny}}, {{user.name}}<br> Kunden-Nr.: {{form.accountID}}<br> Abtlg./Raum-Nr.: Buch (512820)
        <br> Lieferort:<br> Kst. Stelle:<br> Lieferung z. H.: {{form.name}}<br> Rückfragen an: biederbeck@fhvd-sh.de
        <br> Zusatzvermerk: {{form.Freitext}}<br> <br> <br> <br> eB2B-Bestellnummer<br> <br> Bestellt wurden folgende Artikel:
        <br> <br> {{#each cart}} {{@key}}. {{this.qty}}x {{this.title}}<br> {{isbn}}<br> Enthält {{tax}}% MwSt. (Nettopreis {{this.price}} EUR)
        <br> <br> {{/each}} <br> <br> Der Gesamtbetrag EUR beläuft sich auf EUR {{form.invoiceTotal}} <br> <br> Vielen Dank für Ihre Bestellung! 
        <br> <br> <h1>Debug</h1> <h2>Formular Data</h2> <ul>{{#each form}} <li><b>{{@key}}</b> {{this}} </li>{{/each}}</ul> 
        <h2>User Data</h2> <ul>{{#each user}} <li><b>{{@key}}</b> {{this}} </li>{{/each}}</ul> 
        <h2>Warenkorb Data</h2> <ul>{{#each cart}} <li><b>{{@key}}</b> <ul>{{#each this}} <li>{{@key}}: {{this}} </li>{{/each}}</ul> </li>{{/each}}</ul>`
    },
    {
        name: 'orderAbo',
        subject: "[Bestätigung] Abo-Freitext von {{user.cny}}, {{user.name}}({{cart.accountID}})",
        html: `
          Titel: ZEITSCHRIFT<br> 
          Verlag: VERLAG<br> 
          <br> 
          Bestellmenge: 1 Stück<br> 
          Bezugsform: gedruckt/print<br> 
          Versand: Standard<br> 
          Ab Ausgabe: nächstmögliche Ausgabe<br> 
          Beschränkt auf: unbeschränkt bis auf Widerruf<br> 
          Lieferort: <br> 
          <br> 
          Name des Primärbeziehers: Erwin Schmidt<br> 
          Kst. Stelle Primärbezieher: 123456<br> 
          <br> 
          Weitere Bezieher:<br> 
          <br> 
          Name des 2. Beziehers: <br> 
          Kst. Stelle des Beziehers: <br> 
          Name des 3. Beziehers: <br> 
          Kst. Stelle des Beziehers: <br> 
          <br> 
          Name des 4. Beziehers: <br> 
          Kst. Stelle des Beziehers: <br> 
          <br> 
          Freitext:<br> bitte rückwirkend ab 1.1.2023<br> 
          {{#if form.debug}} 
              <h1>Debug</h1> 
              <h2>Formular Data</h2> 
              <ul>
                  {{#each form}} 
                      <li><b>{{@key}}</b> {{this}} </li>
                  {{/each}}
              </ul> 
              <h2>User Data</h2> 
              <ul>
                  {{#each user}} 
                      <li><b>{{@key}}</b> {{this}} </li>
                  {{/each}}
              </ul> 
              <h2>Warenkorb Data</h2> 
              <ul>
                  {{#each cart}} 
                      <li><b>{{@key}}</b> 
                          <ul>
                              {{#each this}} 
                                  <li>{{@key}}: {{this}} </li>
                              {{/each}}
                          </ul> 
                      </li>
                  {{/each}}
              </ul> 
          {{/if}}`
    },
    {
        name: 'orderCart',
        subject: "[Bestätigung] Service-Freitext von {{user.cny}}, {{user.name}} ({{form.accountID}})",
        html: `
          Bestellbestätigung<br> 
          ------------------<br> 
          <br> 
          Bestellt am: {{form.orderDate}} um {{form.orderTime}}<br> 
          Von: {{user.cny}}, {{user.name}}<br> 
          Kunden-Nr.: {{form.accountID}}<br> 
          Kst. Stelle: {{form.costCenter}}<br> 
          Lieferung z. H.: {{form.deliveryName}}<br> 
          Rückfragen an: {{user.email}}<br> 
          Zusatzvermerk: {{form.message}}<br> 
          Zeitschriftenvermerk: {{form.messageMagazine}}<br> 
          <br> 
          <br> 
          <br> 
          eB2B-Bestellnummer<br> 
          <br> 
          Bestellt wurden folgende Artikel:<br> 
          <br> 
          {{#each cart}} 
              {{@key}}. {{this.qty}}x {{this.title}}<br> 
              {{isbn}}<br> 
              Enthält {{tax}}% MwSt. (Nettopreis {{this.nettoPrice}})<br> 
              <br> 
          {{/each}} 
          <br> 
          <br> 
          Der Gesamtbetrag EUR beläuft sich auf {{form.invoiceTotal}} <br> 
          <br> 
          Vielen Dank für Ihre Bestellung! <br> 
          <br>`
    },
    {
        name: 'orderForm',
        subject: "[Bestätigung] Service-Freitext von {{user.cny}}, {{user.name}}({{cart.accountID}})",
        html: `
          Service-Freitext Bücher/Zeitschriften <br> 
          <br> 
          -------------------------------------------------------------------------------<br> 
          Bestellt am: {{form.orderDate}} um {{form.orderTime}}<br> 
          Von: {{user.cny}}, {{user.name}} ({{form.accountID}})<br> 
          Email: {{user.email}}<br> 
          <br> 
          Korrespondenz:<br> 
          {{form.message}}<br> 
          <br> 
          <br> 
          -------------------------------------------------------------------------------<br> 
          Bestellung:<br> 
          <br> 
          Autor:{{form.author}}<br> 
          Titel: {{form.title}}<br> 
          ISBN: {{form.ISBN}}<br> 
          Verlag: {{form.publisher}}<br> 
          <br> 
          Kst. Stelle: {{form.costCenter}} <br> 
          <br> 
          Lieferung z. H.: {{form.deliveryName}}<br> 
          <br> 
          Freitext: <br> 
          {{form.messageOrder}}<br> 
          <br> 
          {{#if form.debug}} 
              <h1>Debug</h1> 
              <h2>Formular Data</h2> 
              <ul>
                  {{#each form}} 
                      <li><b>{{@key}}</b> {{this}} </li>
                  {{/each}}
              </ul> 
              <h2>User Data</h2> 
              <ul>
                  {{#each user}} 
                      <li><b>{{@key}}</b> {{this}} </li>
                  {{/each}}
              </ul> 
              <h2>Warenkorb Data</h2> 
              <ul>
                  {{#each cart}} 
                      <li><b>{{@key}}</b> 
                          <ul>
                              {{#each this}} 
                                  <li>{{@key}}: {{this}} </li>
                              {{/each}}
                          </ul> 
                      </li>
                  {{/each}}
              </ul> 
          {{/if}}`
    },
    {
        name: 'orderIssue',
        subject: 'Litport: Probleme mit der Bestellung {{db.AuftragNr}}',
        html: `
          <h2>Probleme mit der Bestellung {{db.AuftragNr}}</h2> 
          <p>Bei der Bestellung {{db.AuftragNr}} vom {{db.Bestelldatum}} gab es Probleme.</p> 
          <h3>Kunde</h3> 
          Von: {{user.son}} {{user.name}} ({{user.cny}})<br> 
          Kunden-Nr.: {{db.KDNR}}<br> 
          Kst. Stelle: {{db.Kostenstelle}}<br> 
          Rückfragen an: {{user.email}}<br> 
          Zusatzvermerk: <br>{{form.message}}<br> 
          <br> 
          <h3>Details der Bestellung</h3> 
          Titel: {{db.Titel}}</br> 
          ISBN: {{db.ISBN}}</br> 
          Einzelpreis: {{db.Einzelpreis}}</br> 
          <br> 
          Bitte prüfen Sie die Bestellung und kontaktieren Sie den Kunden. 
          <br> 
          {{#if form.debug}} 
              <h2>Debug Data</h2> 
              <ul>
                  {{#each this}} 
                      <li><b>{{@key}}</b> 
                          {{#if this.[0]}} 
                              {{this}} 
                          {{else}} 
                              <ul> 
                                  {{#each this}} 
                                      <li>{{@key}}: {{this}}</li> 
                                  {{/each}} 
                              </ul> 
                          {{/if}} 
                      </li> 
                  {{/each}} 
              </ul> 
          {{/if}}`
    },
];

export default emailTemplates;
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer
} from "react-router-dom";

import { LoginPage } from "./pages/Login";
import Register from "./pages/Register";

import { HomePage } from "./pages/Home";
import { ProfilePage } from "./pages/Profile";
import { WelcomePage } from "./pages/Welcome";

import SearchMeta from "./pages/SearchMeta";

import SearchAI from "./pages/SearchAI";

import SearchVlbApi from "./pages/SearchVlbApi";
import SearchLibriApi from "./pages/SearchLibriApi";


// import SearchPageLibri from "./pages/SearchLibri";
// import SearchPageMagazines from "./pages/SearchMagazines";

import OrderStatus from "./pages/OrderStatus";

import { Cart } from "./pages/Cart";
import { WishList } from "./pages/WishList";
import { Contact } from "./pages/Contact";
import { Abonnements } from "./pages/Abonnements";
import { OrderService } from "./pages/OrderService";

import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import { AuthLayout } from "./components/AuthLayout";

import { UserListPage } from "./pages/UserList";
import { UserPage } from "./pages/User";
import { ImprintPage } from "./pages/Imprint";
import { FAQPage } from "./pages/FAQ";

import { auth } from "./firebase";

import "./styles.css";
// ideally this would be an API call to server to get logged in user data

const getUserData = () =>
  new Promise((resolve) => {

    setTimeout(() => {
      //const user = window.localStorage.getItem("user");
    }, 100)
    const user = auth.currentUser;
    resolve(user);
  }
  );

// for error
// const getUserData = () =>
//   new Promise((resolve, reject) =>
//     setTimeout(() => {
//       reject("Error");
//     }, 3000)
//   );

const ImprintPageExt = () => {
  window.location.href = 'https://brunswiker.de/impressum/';
  return null;
};

const ExternalPage = () => {

};

export const router = createBrowserRouter(

  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >

      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<Register />} />
      </Route>

      <Route path="/web" element={<ProtectedLayout />}>

        <Route path="profile" element={<ProfilePage />} />
        <Route path="welcome" element={<WelcomePage />} />
        <Route path="contact" element={<Contact />} />

        <Route path="user"  >
          <Route index element={<UserListPage />} />
          <Route path=":id" element={<UserPage />} />
        </Route>

        <Route path="searchvlbapi" element={<SearchVlbApi />} />
        <Route path="searchlibriapi" element={<SearchLibriApi />} />
        <Route path="searchmeta" element={<SearchMeta />} />
        <Route path="searchai" element={<SearchAI />} />
        <Route path="orderstatus" element={<OrderStatus />} />
        <Route path="orderservice" element={<OrderService />} />

        <Route path="abonnements/*" element={<Abonnements />} >
          <Route path="abostatus" element={<Abonnements />} />
          <Route path="abosearch" element={<Abonnements />} />
          <Route path="aborequest" element={<Abonnements />} />

          <Route path="aboorder" element={<Abonnements />} />
          <Route path="abochange" element={<Abonnements />} />
          <Route path="abodelete" element={<Abonnements />} />
          <Route path="abocontact" element={<Abonnements />} />
          <Route path="aboexport" element={<Abonnements />} />
        </Route>

        <Route path="cart" element={<Cart />} />
        <Route path="wishlist" element={<WishList />} />
        <Route path="impressum" element={<ImprintPageExt />} />
        <Route path="faq" element={<FAQPage />} />

      </Route>
    </Route>
  )
);

import React, { useReducer, createContext, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

// initial state
const state = {
    cart: {},
    wish: {},
};

// https://stackoverflow.com/questions/64547044/persist-localstorage-with-usereducer


export const initializer = (initialValue = state) => {
    console.log("Initialize State");

    try {
        const uid = localStorage.getItem("uid");
        console.log(uid);

        if (typeof uid !== 'undefined' && localStorage.getItem(uid + "cart") !== null) {
            initialValue.cart = JSON.parse(localStorage.getItem(uid + "cart"));
        }
        else {
            initialValue.cart = {};
        }
        if (typeof uid !== 'undefined' && localStorage.getItem(uid + "wish") !== null) {
            initialValue.wish = JSON.parse(localStorage.getItem(uid + "wish"));
        } else {
            initialValue.wish = {};
        }
    } catch (err) {
        console.log(err);
    }


    return initialValue;
}

const Context = createContext();

function cartReducer(state, action) {
    var item;
    switch (action.type) {
        case "ADD_TO_CART":

            item = state.cart[action.payload.isbn];

            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.payload.isbn]: item ? {
                        ...item,
                        qty: item.qty + 1,
                    } : {
                        ...action.payload,
                        qty: 1,
                    }
                }
            }
        case "REMOVE_FROM_CART":
            let newCart = { ...state.cart };
            delete newCart[action.payload.isbn];
            return {
                ...state,
                cart: newCart,
            }
        case "CLEAR_CART":
            return {
                ...state,
                cart: {},
            }
        case "REMOVE_ONE_FROM_CART":
            item = state.cart[action.payload.isbn];

            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.payload.isbn]: item ? {
                        ...item,
                        qty: item.qty > 1 ? item.qty - 1 : 1,
                    } : {
                        ...action.payload,
                        qty: 1,
                    }
                }
            }
        case "ADD_TO_WISH":


            return {
                ...state,
                wish: {
                    ...state.wish,
                    [action.payload.isbn]: item ? {
                        ...item,
                        qty: item.qty + 1,
                    } : {
                        ...action.payload,
                        qty: 1,
                    }
                }
            }
        case "REMOVE_FROM_WISH":
            let newWish = { ...state.wish };
            delete newWish[action.payload.isbn];
            return {
                ...state,
                wish: newWish,
            }
        case "REMOVE_ONE_FROM_WISH":
            console.log(action);

            return {
                ...state,
                wish: {
                    ...state.wish,
                    [action.payload.isbn]: item ? {
                        ...item,
                        qty: item.qty > 1 ? item.qty - 1 : 1,
                    } : {
                        ...action.payload,
                        qty: 1,
                    }
                }
            }
        default:
            return state;
    }
}

// context provider
const Provider = ({ children }) => {

    const { user } = useAuth();

    const [state, dispatch] = useReducer(cartReducer, [], initializer);

    console.log("Provider  state:", state);
    console.log("Provider  user:", user);

    useEffect(() => {
        try {
            if (typeof user.uid !== 'undefined' && user !== null) {
                window.localStorage.setItem("uid", user.uid);
                window.localStorage.setItem(user.uid + "cart", JSON.stringify(state.cart));
                window.localStorage.setItem(user.uid + "wish", JSON.stringify(state.wish));
            }

        } catch (err) { }

    }, [state.cart, state.wish]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider }; 
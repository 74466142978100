import React, { Component, useState, useEffect } from 'react'
import { Grid, Box } from '@mui/material/';

import Link from '@mui/material/Link';
import { Typography } from '@mui/material/';
import { Link as RouterLink } from 'react-router-dom';


import { useAuth } from "../hooks/useAuth";
import { AccountSelect, AccountSelectCheck } from "./AccountSelect";
import Logo from './Logo';
import firebaseConfig from "../config/firebaseConfig";


const preventDefault = (event) => event.preventDefault();

export default function NavTop() {
    const { user, claims } = useAuth();

    return (
        <Box
            sx={{
                p: 1, typography: 'body2',
            }}
        >
            <Grid container display="flex" spacing={2}>

                <Grid container item xs={12} md={6}
                    sx={{
                        mt: { xs: "10px" },
                        justifyContent: { xs: "center", md: "flex-start" },

                    }}
                    alignItems="center"
                >
                    <Link component={RouterLink} to={'/'} href="#">
                        <Logo />
                    </Link>
                </Grid>

                <Grid container item xs={12} md={6}

                    sx={{
                        display: 'flex',
                        justifyContent: { xs: "space-between", md: "flex-end", lg: "" },

                    }}
                    alignItems="center"
                >
                    <Grid
                        sx={{
                            display: 'flex',
                        }}
                        alignItems="center"
                    >

                        {user.displayName}
                        <AccountSelectCheck
                            sx={{
                                m: 1,
                                minWidth: 130,
                            }}
                        />
                    </Grid>

                    <Grid
                        sx={{
                            typography: 'body2', color: 'text.secondary', display: 'flex', mb: 1, mt: 1,
                            '& > :not(style) + :not(style)': {
                                ml: 2,
                            },
                        }}
                    >
                        {user && (claims?.role == "Admin" || claims?.role == "SuperAdmin") &&
                            <Link component={RouterLink} to={'user'}>
                                User anzeigen
                            </Link>
                        }

                        <Link component={RouterLink} to={'contact'} href="#">
                            Kontakt
                        </Link>

                        <Link component={RouterLink} to={'faq'} href="#">
                            FAQ
                        </Link>

                        <Link component="a" href={firebaseConfig.imprintLink} target="_blank" rel="noopener noreferrer">
                            Impressum
                        </Link>

                    </Grid>
                </Grid>
            </Grid>
        </Box >

    );
}
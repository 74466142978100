import React, { useState, useEffect } from 'react'
import {
    Box, Card, CardContent, CardMedia, CardActions,
    Typography, Button, Skeleton, Snackbar, IconButton, Alert
} from '@mui/material/';
import { Context } from "../context/appContext";
import Tooltip from '@mui/material/Tooltip'


export const ResultViewRight = ({ isbn, title, price, tax, engine }) => {

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const handleAddToCart = () => {

        setIsAlertOpen(true);
    };

    const handleAlertClose = () => {
        setIsAlertOpen(false);
    };

    const { dispatch } = React.useContext(Context);

    // check if isbn is german
    const isGerman = isbn && isbn.startsWith("9783");
    const isMagazine = engine == "magazines";

    var toolTip = isGerman ? "" : isMagazine ? "Bitte im Freitext-Feld im Warenkorb den Abo-Typ angeben" : "Es handelt sich um einen Richtpreis für ausländische Bücher";

    const numberFormat = (value) =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

    var priceNew = isMagazine ? "auf Anfrage" : numberFormat(price);
    return (

        <>
            <Snackbar open={isAlertOpen} autoHideDuration={3000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} >
                    {`${title} wurde in den Warenkorb gelegt.`}
                </Alert>
            </Snackbar>

            <Typography gutterBottom variant="h5" color="text.secondary">


                <Tooltip className='' title={`${toolTip}`}>
                    <span className="material-icons" style={{ color: "grey" }}> {priceNew}{isGerman ? "" : "*"}</span>
                </Tooltip>
            </Typography>


            <Typography variant="body2" color="text.secondary">
                inkl. {tax}% MwSt.
            </Typography>

            <Button size="medium"
                onClick={() => dispatch({ type: "ADD_TO_WISH", payload: { "isbn": isbn, "title": title, "price": parseFloat(price), "tax": tax } })}
            >Merken</Button >

            <Button variant='contained'
                size="large"
                onClick={() => {
                    dispatch({ type: "ADD_TO_CART", payload: { "isbn": isbn, "title": title, "price": parseFloat(price), "tax": tax } });
                    handleAddToCart();

                }}
            >Bestellen</Button >
        </>


    );
}

export const ResultView = ({ item }) => {

    const [showMore, setShowMore] = useState(false);

    const handleImageError = (e) => {
        e.target.onerror = null;
        // e.target.style.display = 'none'
        e.target.src = "https://via.placeholder.com/200"
    }

    const { dispatch } = React.useContext(Context);

    useEffect(() => {
        // console.log("ResultView: " + item.id);

    }, [showMore]);

    function formatISBN(isbn) {
        if (!isbn) return;
        return isbn.replace(/(\d{3})(\d{1})(\d{4})(\d{4})(\d{1})/, "$1-$2-$3-$4-$5");
    }

    // map 3 characters language code to cleartext in german für all languages

    ;
    const langMap = {
        "ger": "Deutsch",
        "eng": "Englisch",
        "fre": "Französisch",
        "ita": "Italienisch",
        "spa": "Spanisch",
        "por": "Portugiesisch",
        "rus": "Russisch",
        "pol": "Polnisch",
        "dut": "Niederländisch",
        "swe": "Schwedisch",
        "fin": "Finnisch",
        "dan": "Dänisch",
        "nor": "Norwegisch",
        "gre": "Griechisch",
        "tur": "Türkisch",
        "chi": "Chinesisch",
        "jpn": "Japanisch",
        "kor": "Koreanisch",
        "ara": "Arabisch",
        "heb": "Hebräisch",
        "hin": "Hindi",
        "tha": "Thailändisch",
        "vie": "Vietnamesisch",
        "ind": "Indonesisch",
        "per": "Persisch",
        "rum": "Rumänisch",
        "bul": "Bulgarisch",
        "cze": "Tschechisch",
        "slo": "Slowakisch",
        "hun": "Ungarisch",
        "est": "Estnisch",
        "lav": "Lettisch",
        "lit": "Litauisch",
        "slv": "Slowenisch",
        "mac": "Mazedonisch",
        "alb": "Albanisch",
        "arm": "Armenisch",
        "aze": "Aserbaidschanisch",
        "bel": "Weißrussisch",
        "bos": "Bosnisch",
        "geo": "Georgisch",
    }
    // console.log(item.coverUrl ? item.coverUrl : 'https://via.placeholder.com/100x150');


    return (


        <Card
            sx={{
                display: 'flex',
                flexDirection: { xs: "column", md: "row" },
                // alignItems: "center",
                // justifyContent: "center",
                p: 2,
                mb: 2,
                '& .markdown': {
                    py: 3,
                },
            }}
            key={item.id}
        >
            <Box

                sx={{
                    // backgroundColor: "#ddd",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: { md: "flex-start", xs: "center" },
                    mt: { xs: 0, md: 2 },
                }}
            >
                <CardMedia
                    component="img"
                    sx={{
                        // width: 150, 
                        width: { xs: "100%", sm: 350, md: 250 },
                        height: "auto",

                    }}
                    image={item.coverUrl ? item.coverUrl : 'https://via.placeholder.com/100x150'}
                    alt=""
                    onError={handleImageError}
                />
            </Box>

            <CardContent

                sx={{
                    flex: '1 100%',
                    // backgroundColor: "#eee",
                    flexWrap: "wrap",
                }}
            >
                <Typography gutterBottom variant="body2" color="text.secondary">

                    {item.contributortext}

                    {/* {item.contributors &&
                                        item.contributors.map((contributor, contributorIndex) => {
                                            return (
                                                <>
                                                    {contributor.type}: {contributor.firstName} {contributor.lastName}
                                                </>
                                            );
                                        })} */}
                </Typography>

                <Typography gutterBottom variant="h5" component="h1">
                    {item.title}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    <strong>ISBN: </strong> {formatISBN(item.isbn)}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    <strong>Sprache: </strong> {item.language}
                </Typography>



                <Typography variant="body2" color="text.secondary">
                    {item.pages && item.pages + " Seiten;"} {item?.productForm && item?.productForm + "; "} {langMap[item?.language] && langMap[item?.language] + "; "}     {item?.engine}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    <strong>Verlag: </strong> {item.publisher} {item.publishingYear && "(" + item.publishingYear + ")"}
                </Typography>
                {/* 
                <Typography variant="body2" color="text.secondary" sx={{ pt: 2 }}>
                    <strong>DEBUG: </strong><br /> File: {item.fn} <br />Imported: {item.dt} <br /> ISBN: {item.isbn} <br />Score: {item.score}
                </Typography> */}

                <Typography variant="body2" color="text.secondary">
                    {item.engine != "magazines" && item?.quantityStock && item.quantityStock > 0 && "Verfügbarkeit: " + item.quantityStock + " Exemplare"}
                    {item.engine == "magazines" && "... hier noch Text zu den Abo Modellen ..."}
                </Typography>


                <Typography sx={{ pt: 2 }} gutterBottom variant="body2" color="text.secondary">
                    <span dangerouslySetInnerHTML={{ __html: showMore ? item.content : item.contentShort }}>
                    </span>
                    {item.contentShort && item.contentShort !== item.content &&
                        <Button
                            sx={{ pl: 0, ml: 0 }}
                            size='small'
                            onClick={() => setShowMore(!showMore)}>
                            {showMore ? " weniger" : " ... mehr"}
                        </Button>
                    }


                </Typography>

            </CardContent>

            <CardActions
                sx={{
                    flex: '1 0 150px',
                    alignSelf: { md: "flex-end" },
                    flexDirection: {
                        xs: "column",

                        // , md: "row"  
                    },
                }}
            >
                <ResultViewRight
                    price={item.price}
                    title={item.title}
                    isbn={item.isbn}
                    tax={item.tax}
                    engine={item.engine}
                    sx={{}}
                />

            </CardActions>

        </Card >

    );
}

export const ResultViewElastic = (props, handleClick) => {

    if (!props.result) return (<></>);

    let coverUrl = props.result.coverurl?.raw;

    if (props.result.engine.raw == "vlb" || props.result.engine.raw == "libri" || props.result.engine.raw == "newbooks") {

        coverUrl = 'https://images.brunswiker.de/' + props.result.isbn.raw.slice(-2) + "/" + props.result.isbn.raw + ".jpg";
    }

    console.log(props);
    return (
        <ResultView
            item={{
                "id": props.result.id.raw,
                "isbn": props.result.isbn.raw,
                "title": props.result.title?.raw,
                "contributors": props.result?.contributor?.raw,
                "content": props.result.content?.raw,
                "contentShort": props.result.content?.snippet,
                "price": props.result?.pricede?.raw,
                "tax": props.result?.taxde?.raw,
                "language": props.result.language?.raw,
                "publisher": props.result.publisher?.raw,
                "publishingDate": props.result.publishingdate?.raw,
                "publishingYear": props.result.publishingdate?.raw ? parseInt(props.result.publishingdate?.raw) : "",
                "pages": props.result.pages?.raw,
                // cover: props.result.cover.raw,
                "contributortext": props.result.contributortext?.raw,
                "productForm": props.result?.productform?.raw,
                "coverUrl": coverUrl,
                "tax": props.result?.taxde?.raw,
                "engine": props.result?.engine.raw,
                "fn": props.result?.fn?.raw,
                "dt": props.result?.dt?.raw,
                "score": props.result?._meta?.score

            }}>

        </ResultView>
    )
}

export const ResultViewApiVLB = ({ item }) => {

    function getContributorText(item) {
        if (item.contributors) {
            let contributorText = "";
            item.contributors.forEach((contributor, index) => {
                contributorText += contributor.type + ": " + contributor.firstName + " " + contributor.lastName;
                if (index < item.contributors.length - 1) {
                    contributorText += ", ";
                }
            });
            return contributorText;
        }
    }

    return (

        <ResultView
            item={{
                "id": item.gtin,
                "isbn": item.isbn,
                "title": item.title,
                "contributors": item.contributors,
                "content": item.mainDescription,
                "contentShort": item.mainDescription ? item.mainDescription.replace(/^(.{120}[^\s]*).*/, "$1") : "",
                "price": (item.priceEurD),
                "publisher": item.publisher,
                "pubslishingDate": item.publicationDate,
                "publishingYear": item.publicationDate.split(".")[2],

                // cover: props.result.cover.raw,
                "contributortext": getContributorText(item),
                "language": item.language,
                "productForm": item.productForm,
                "productType": item.productType,
                "pages": item.pagesArabic,
                "coverUrl": 'https://europe-west1-litport-web.cloudfunctions.net/app/cover/' + item.gtin,
            }}>
        </ResultView>
    )
}


export const ResultViewApiLibri = ({ item }) => {

    function getPriceForDe(item) {

        if (item.prices) {
            let priceDE = 0;
            item.prices.forEach((price, index) => {

                if (price.countriesIncluded[0] == "DE") {
                    priceDE = price.priceAmount;
                }
            });
            return priceDE;
        }
    }


    function getTaxForDe(item) {

        if (item.prices) {
            let priceDE = 0;
            item.prices.forEach((price, index) => {

                if (price.countriesIncluded[0] == "DE") {
                    priceDE = price.taxRateCode;

                }
            });
            return priceDE;
        }
    }
    //console.log(item);



    const taxRateCodeToPercent = (taxRateCode) => {
        switch (taxRateCode) {
            case "R":
                return 7;
            case "S":
                return 19;
            case "C":
                return 0;
            default:
                return 0;
        }
    }


    return (

        <ResultView
            item={{
                "id": item.primaryEAN,
                "isbn": item.primaryEAN,
                "title": item.title,
                "contributors": item.contributors,
                "content": item.mainDescription,
                "contentShort": item.mainDescription ? item.mainDescription.replace(/^(.{120}[^\s]*).*/, "$1") : "",
                "price": (getPriceForDe(item)),
                "publisher": item.publisherName,
                "publishingDate": item.publishingDate.date,
                "publishingYear": item.publishingDate.date.split("-")[0],

                // cover: props.result.cover.raw,
                "contributortext": item.mainContributor,
                "language": item.language,
                "productForm": item.productForm,
                "productType": item.productType,
                "pages": item.pagesArabic,
                "quantityStock": item.libriStockQuantityCoded,
                "tax": taxRateCodeToPercent(getTaxForDe(item)),
                "coverUrl": item.coverImages ? item.coverImages[0].uri : "",
            }}>
        </ResultView>
    )
}
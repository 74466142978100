import React, { Component, useState, useEffect, useRef } from 'react';
import {
    FormContainer, TextFieldElement, PasswordRepeatElement,
    CheckboxElement, PasswordElement, SelectElement
} from 'react-hook-form-mui'

import {
    Container, Grid, TextField, Typography,
    Snackbar, Button, Alert, TextareaAutosize, Stack, Textfield,
    Hidden, InputLabel, Select, MenuItem, FormControl, FormLabel, RadioGroup,
    FormHelperText, FormControlLabel, Radio, Checkbox, Link
} from "@mui/material/";


import { useFormContext } from 'react-hook-form'

import { AccountSelect } from "../components/AccountSelect";

import { useAuth } from "../hooks/useAuth";

import { sendEmailTemplate } from "../firebase";


export const FadingAlert = (props) => {
    const [open, setOpen] = useState(false);
    const errorCountRef = useRef(0);

    useEffect(() => {

        const timer = setTimeout(() => {
            setOpen(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };

    }, [props.severity, props.message, open]);

    return (
        open && (
            <Alert severity={props.severity} sx={{ width: '100%' }}>
                {props.children}
            </Alert>
        )
    );
}

export const FieldsContact = () => {
    const { user, claims, accountID } = useAuth();

    return (
        <>

            <Typography>
                <b>Bestellung von:</b> {claims?.name} ({claims?.email})
            </Typography>

            <AccountSelect sx={{ minWidth: 140, maxWidth: 140 }}></AccountSelect>

            <TextFieldElement
                size="small"
                name="deliveryName"
                label="Lieferung zu Händen von (Name, Vorname)"
            />

            <TextFieldElement
                size="small"
                name="costCenter"
                label="Kostenstelle"
                placeholder="*"
            />

            <FieldMessage></FieldMessage>
        </>
    )
}

export const FieldsMagazine = () => {
    const { user, claims, accountID } = useAuth();

    return (
        <TextFieldElement
            label={'Infos zur Zeitschrift'}
            name={'messageMagazine'}
            placeholder="Bitte geben Sie weitere Infos zur Zeitschriften-Bestellung an: z.B. Abo-Art, Abo-Start, Einzelausgabe, ..."
            multiline
            rows={4}
        />
    )
}



export function ResetFormButton() {
    const { reset } = useFormContext()
    return (
        <Button
            onClick={() => {
                reset({})
            }}
        >
            Formular zurücksetzen
        </Button>
    )
}

export const FieldMessage = () => {
    return (
        <TextFieldElement
            label={'Freitext'}
            name={'message'}
            placeholder="Ihre Nachricht"
            multiline
            rows={4}
        />
    )
}

export const ButtonSubmit = () => {

    return (
        <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            sx={{ mt: 3, mb: 2 }}
        >
            Abschicken
        </Button>
    )
}

export const FieldName = () => {
    const { user, claims } = useAuth();

    return (
        <TextFieldElement
            required
            label={'Name'}
            name={'Name'}
            defaultValue={claims?.displayName}
        />
    )
}

export const FormContact = (props) => {
    const { user, claims, accountID } = useAuth();
    const [name, setName] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const currentRow = props.currentRow || {};

    const handleClose = props.handleClose || (() => { });
    const template = props.template || "debug";

    const onSubmitHandler = (formData) => {
        var data = {};
        data.form = {};
        data.user = {};
        data.db = {};

        data.template = template;


        Object.assign(data.form, formData);
        data.form.accountID = accountID;
        Object.assign(data.user, claims);
        Object.assign(data.db, currentRow);

        sendEmailTemplate(data);
        setSuccess("Ihre Nachricht wurde erfolgreich versendet.");
        handleClose();
    }

    return (
        <FormContainer
            onSuccess={onSubmitHandler}

            FormProps={{
                'aria-autocomplete': 'none',
                autoComplete: 'new-password'
            }}>
            <Stack spacing={2}
                // minWidth={'600px'}
                sx={{
                }}
            >

                <Typography>
                    <b>Nachricht von:</b> {claims?.name} ({claims?.email})<br />
                </Typography>


                <TextFieldElement
                    label={'Nachricht'}
                    name={'message'}
                    placeholder="Ihre Nachricht"
                    multiline
                    rows={4}
                />

                <Button type={'submit'} color={'primary'} variant={'contained'}>
                    Abschicken
                </Button>
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}

            </Stack>
        </FormContainer>
    )
}


import { BasicPage } from "../components/BasicPage";
import { useAuth, apiFetch } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import {
    Table, TableBody, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton,
    LinearProgress
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Edit, Delete, DeleteOutlinedIcon, Add, Person } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Stack, Link, ButtonGroup, Button, Alert, Typography, FormControlLabel } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
import dayjs from 'dayjs';

export const UserListPage = () => {
    const confirm = useConfirm();
    const { claims, token } = useAuth();

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const [loading, setLoading] = useState(false)
    const [fromFetch, setFromFetch] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [axiosData, setAxiosData] = useState(null);
    const [data, setData] = useState("");
    const [deleted, setDeleted] = useState(false);



    const handleClick = (user) => {

        console.log(user);
        confirm({
            title: "Profil löschen",
            description: "Das Profil von " + user.displayName + " wird gelöscht.",
            confirmationText: "Löschen",
            cancellationText: "Abbrechen",

        })
            .then(() => {
                deleteUserById(user.uid);
            })
            .catch(() => {
                /* ... */
            });
    };

    const deleteUserById = (id) => {

        apiFetch("/app/users/" + id, { method: "DELETE" })
            .then((res) => res.json())
            .then((data) => {
                setDeleted(true);
                setMessage("User wurde gelöscht.");
            });
    }

    useEffect(() => {
        setLoading(true);
        setError("");

        apiFetch("/app/users/")
            .then((res) => res.json())
            .then((data) => {
                setData(data)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });

        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            // setMessage("");
            // setDeleted(false);
        }, 3000)

        return () => {
            clearTimeout(timeId)
        }
    }, [deleted]);


    return (
        < BasicPage title="Profile" icon={< Person />} >

            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}

            <Box
                mt={2}
                mb={2}
                sx={{ width: '100%', height: 4 }}>
                {loading && <LinearProgress />}
            </Box>

            <Box sx={{ width: '100%', overflow: 'hidden', mb: 5 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    component={RouterLink}
                    to="new"
                >
                    Profil hinzufügen
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{}} aria-label="simple table">

                    <TableHead >
                        <TableRow>
                            <StyledTableCell>Mandant</StyledTableCell>
                            <StyledTableCell>Firma / Unternehmen</StyledTableCell>
                            <StyledTableCell>Anrede</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Kunden-Nr.</StyledTableCell>
                            <StyledTableCell>Benutzerrechte</StyledTableCell>
                            <StyledTableCell>Account erstellt am</StyledTableCell>
                            <StyledTableCell>letzte Anmeldung</StyledTableCell>
                            <StyledTableCell>bearbeiten / löschen</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data && data.users && data.users.map((user, index) => (
                            <TableRow
                                key={user.uid}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{user?.customClaims?.tnt}</TableCell>
                                <TableCell>{user?.customClaims?.cny}</TableCell>
                                <TableCell>{user?.customClaims?.son}</TableCell>
                                <TableCell>{user.displayName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user?.customClaims?.aid}</TableCell>
                                <TableCell>{user?.customClaims?.role}</TableCell>
                                <TableCell>{dayjs(user?.metadata?.creationTime).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>{dayjs(user?.metadata?.lastSignInTime).format('DD.MM.YYYY HH:MM')}</TableCell>
                                <TableCell>
                                    {((claims.role === "Admin" && user?.customClaims?.role !== "SuperAdmin") || claims.role === "SuperAdmin" || true) &&
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="User-Einstellungen öffnen">
                                                <IconButton
                                                    component={RouterLink}
                                                    to={user.uid}
                                                    color="inherit"
                                                    sx={{
                                                        p: 0
                                                    }}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="User löschen">
                                                <IconButton
                                                    //onClick={() => deleteUserById(user.uid)}
                                                    onClick={() => handleClick(user)}
                                                    color="inherit"
                                                    sx={{
                                                        p: 0
                                                    }}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </BasicPage>

    );
};


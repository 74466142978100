import React, { Component, useState, useEffect } from 'react'
import { BasicPage } from "../components/BasicPage";

import {
    Grid, Container, Stack, Typography,
    Alert, Button, TextField
} from '@mui/material/'

import { sendEmailTemplate } from "../firebase";

import {
    FormContainer, TextFieldElement, PasswordRepeatElement,
    CheckboxElement, PasswordElement, useForm, useFormContext
} from 'react-hook-form-mui'

//import {} from 'react-hook-form'
import ShoppingCart from '@mui/icons-material/ShoppingCart';

import { useAuth } from "../hooks/useAuth";
import { FieldsContact, ResetFormButton } from '../components/FormFields';
import { FadingAlert } from '../components/FormFields';
import dayjs from 'dayjs';

export const OrderService = () => {

    const { user, claims, accountID } = useAuth();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const [submittedData, setSubmittedData] = useState(false);
    const formContext = useForm();
    const { reset, formState } = formContext;

    useEffect(() => {
        console.log("formState.isSubmitSuccessful", formState.isSubmitSuccessful);

        if (formState.isSubmitSuccessful) {
            reset({});

        }
    }, [formState.isSubmitSuccessful, submittedData, reset]);



    const onSubmit = (formData) => {

        setSubmittedData(formData);
        setError("");
        setSuccess("");

        var data = {};
        data.form = {};
        data.user = {};

        if (!accountID) {
            setError("Bitte wählen Sie einen Account aus.");
            return;
        }

        if (!formData.author && !formData.title && !formData.ISBN && !formData.publisher) {
            setError("Die Felder enthalten keine Daten. Bitte füllen Sie mindestens ein Feld aus.");
            return;
        }

        data.template = "orderForm";
        data.form.accountID = accountID;
        data.form.orderDate = new Date().toLocaleDateString();
        data.form.orderTime = new Date().toLocaleTimeString();

        Object.assign(data.form, formData);
        Object.assign(data.user, claims);

        sendEmailTemplate(data);
        setSuccess("Ihre Bestellung wurde erfolgreich versendet.");
    }


    return (
        <BasicPage title="Bestell-Service" icon={<ShoppingCart />}>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <FormContainer
                    defaultValues={{ name: '' }}
                    formContext={formContext}
                    onSuccess={(data) => {
                        setSubmittedData(data);
                        onSubmit(data);
                    }}
                >

                    <Stack spacing={2}

                        sx={{
                            width: 'auto'
                        }}
                    >
                        <Typography component="h2" variant="h5">
                            Kontakt
                        </Typography>

                        <span>Bitte ausfüllen, soweit Daten bekannt</span>

                        <FieldsContact></FieldsContact>


                        <Typography component="h2" variant="h5">
                            Bestellung
                        </Typography>
                        <span>Bitte ausfüllen, soweit Daten bekannt</span>

                        <TextFieldElement
                            size="small"
                            label={'Autor'}
                            name={'author'}

                        />

                        <TextFieldElement
                            size="small"
                            label={'Titel'}
                            name={'title'}
                        />

                        <TextFieldElement
                            size="small"
                            label={'ISBN'}
                            name={'ISBN'}
                        />

                        <TextFieldElement
                            size="small"
                            label={'Verlag'}
                            name={'publisher'}
                        />

                        <TextFieldElement
                            size="small"
                            label={'Kostenstelle'}
                            name={'costCenterOrder'}
                        />

                        <TextFieldElement
                            size="small"
                            label={'Lieferung zu Händen von (Name, Vorname)'}
                            name={'deliveryNameOrder'}
                        />

                        <TextFieldElement
                            label={'Freitext'}
                            name={'messageOrder'}
                            placeholder="Ihre Nachricht"
                            multiline
                            rows={4}

                        />

                        {error && <Alert severity="error">{error}</Alert>}
                        {success && <Alert severity="success">{success}</Alert>}

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            size="large"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Bestellung absenden
                        </Button>

                        <ResetFormButton></ResetFormButton>

                    </Stack>
                </FormContainer>
            </Grid>

        </BasicPage >

    );
}


import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function PaginationApi({ page, onChangePage, rowsPerPage, onChangeRowsPerPage }) {
    //const [page, setPage] = React.useState(page);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        page = 0;
        onChangeRowsPerPage(parseInt(event.target.value, 10));
    };

    return (
        <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Zeilen pro Seite"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} von ${count}`}

        />
    );
}
import * as React from "react";

import FavoriteIcon from '@mui/icons-material/Favorite';

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";

import {
    Container, Button, MenuItem, Badge, Toolbar, Box, IconButton,
    Typography, Menu, Tooltip, Avatar, AppBar
} from "@mui/material/";


import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Context } from "../context/appContext";

import { useLocation } from "react-router-dom";


export const NavMain = ({ pages, settings, settingsAbo }) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [activeButton, setActiveButton] = React.useState("");

    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const location = useLocation()

    let itemCount = 0;
    let itemCountWishes = 0;

    if (user) {
        const { state } = React.useContext(Context);
        const cart = state.cart;
        const wish = state.wish;

        if (cart) {
            for (const [key, value] of Object.entries(cart)) {
                itemCount = itemCount + cart[key]["qty"];
            };
        }
        if (wish) {

            itemCountWishes = Object.entries(wish).length;

        }
    }


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (path) => {
        setAnchorElNav(null);
        setActiveButton(path);
        if (path) {
            navigate(path);
        }
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenComponentMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = (path) => {
        setAnchorElUser(null);
        if (path) {
            navigate(path);
        }
    };



    return (

        <AppBar position="static" >

            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/*  Mobile Menu */}
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" }
                            }}
                        >
                            {pages?.map((page) => (
                                <MenuItem
                                    key={page.label}
                                    onClick={() => handleCloseNavMenu(page.path)}
                                >
                                    <Typography textAlign="center">{page.label}</Typography>
                                </MenuItem>
                            ))}


                        </Menu>


                    </Box>

                    {/* Standard Menu */}
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>

                        {pages?.map((page) => (
                            <Button
                                key={page.label}
                                onClick={() => handleCloseNavMenu(page.path)}
                                sx={{ my: 2, display: "block" }}
                                className={activeButton === page.path ? "active-button" : ""}
                                size='large'
                            >
                                {page.label}

                            </Button>
                        ))}


                    </Box>

                    {!!user && (
                        <>

                            <Tooltip title="Merkliste anzeigen">
                                <IconButton
                                    aria-label="wishes"

                                    onClick={() => handleCloseNavMenu("WishList")}
                                    sx={{ my: 2, display: "block" }}
                                    icon={<FavoriteIcon />}
                                >
                                    <Badge badgeContent={itemCountWishes} color="secondary">
                                        <FavoriteIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Warenkorb anzeigen">
                                <IconButton
                                    aria-label="cart"

                                    onClick={() => handleCloseNavMenu("Cart")}
                                    sx={{ my: 2, display: "block" }}
                                    icon={<ShoppingCartIcon />}
                                >
                                    <Badge badgeContent={itemCount} color="secondary">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Profil-Einstellungen öffnen">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        color="inherit"
                                        sx={{
                                            p: 0
                                        }}>
                                        <Avatar
                                            sx={{
                                                backgroundColor: "inherit"
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting.path}

                                            onClick={() => handleCloseUserMenu(setting.path)}
                                        >
                                            <Typography textAlign="center">{setting.label}</Typography>
                                        </MenuItem>
                                    ))}

                                    <MenuItem key={"logout"} onClick={logout}>
                                        Logout
                                    </MenuItem>

                                </Menu>

                            </Box>
                        </>
                    )}

                </Toolbar>
            </Container>
        </AppBar >
    );
};

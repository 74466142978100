import * as React from "react";
import { Context } from "../context/appContext";
import { BasicPage } from "../components/BasicPage";
import { useAuth } from "../hooks/useAuth";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import { createContext, useContext, useEffect, useState } from "react";

import {
    Container, Grid, Divider, Box, Card, CardContent, CardMedia, CardActions,
    Typography, Button, Stack
} from '@mui/material/';


export const WishList = () => {
    const { state } = React.useContext(Context);
    const { dispatch } = React.useContext(Context);
    const { user, claims } = useAuth();
    const { wish } = state;
    console.log(wish);

    const handleImageError = (e) => {
        e.target.onerror = null;
        // e.target.style.display = 'none'
        e.target.src = "https://via.placeholder.com/200"
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

    return (
        <BasicPage title="Merkliste" icon={<FormatListBulleted />} >
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >

                {wish && Object.entries(wish).map(([key, item]) => (

                    <Grid item xs={12} sm={6} md={3} key={key} mt={4} >

                        <Card
                            sx={{
                                maxWidth: 200,
                                alignItems: "center",
                                justifyContent: "center",

                            }}
                            key={item.id}
                        >
                            <CardMedia
                                sx={{ width: 200, height: 300 }}
                                image={'https://images.brunswiker.de/' + item.isbn.slice(-2) + "/" + item.isbn + ".jpg"}
                                title=""
                                onError={handleImageError}
                            />

                            <CardContent>
                                <Typography gutterBottom sx={{ fontSize: "16" }} component="div">
                                    {item.title}
                                </Typography>

                                <Typography gutterBottom variant="" color="text.secondary">
                                    {(item.isbn)}
                                </Typography>

                            </CardContent>

                            <CardActions
                                sx={{
                                    flexDirection: {
                                        xs: "column",

                                    },
                                    width: "100%",
                                    justify: "center",
                                    alignItems: "center"

                                }}
                            >

                                <Typography gutterBottom variant="h5" color="text.secondary">
                                    {numberFormat(item.price)}
                                </Typography>


                                <Button size="medium"
                                    onClick={() => dispatch({ type: "REMOVE_FROM_WISH", payload: { "isbn": item.isbn } })}
                                > Entfernen</Button>


                                <Button variant='contained'
                                    size="large"
                                    onClick={() => dispatch({ type: "ADD_TO_CART", payload: { "isbn": item.isbn, "title": item.title, "price": parseFloat(item.price), "tax": item.tax } })}
                                > Bestellen</Button>


                            </CardActions>
                        </Card >
                    </Grid >
                ))
                }
            </Grid >
        </BasicPage >

    )

};

import * as React from 'react';
import { Component, useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAuth } from "../hooks/useAuth";

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';

export function AccountSelect(props) {

    let sx = props.sx;

    const { user, claims, accountID, changeAccountID } = useAuth();


    // useEffect(() => {
    //     setAccountId(claims?.aid?.split(",")[0])
    // }, []);

    const handleChange = (event) => {
        changeAccountID(event.target.value);
    };

    return (
        <FormControl sx={sx} size="small">
            <InputLabel id="account-select-small">Kunden-Nr.</InputLabel>
            <Select
                labelId="account-select-small"
                id="account-select-small"
                value={accountID}
                label="Kundennummer"
                onChange={handleChange}
            >
                {claims?.aid?.split(",").map((aid) => (
                    <MenuItem key={aid} value={aid}>{aid}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function AccountSelectCheck(props) {

    let sx = props.sx;

    const { user, claims, accountID, changeAccountID } = useAuth();

    const [selectedValues, setSelectedValues] = React.useState(accountID);

    const handleChange = (event) => {
        const newValue = event.target.value;
        console.log("handleChange: ", newValue);

        // Check if the new value has at least one selected option
        if (newValue.length > 0) {
            setSelectedValues(newValue);
            changeAccountID(newValue);
        }
    };
    return (
        <FormControl variant="outlined" fullWidth sx={{ marginRight: '1rem', minWidth: '120px' }}>
            <InputLabel id="demo-multiple-checkbox-label">Kundennummer</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedValues}
                onChange={handleChange}
                label="Kundennummer"
                renderValue={(selected) => `ausgewählt (${selected.length})`}
            >
                {claims?.aid?.split(",").map((kdnr) => (
                    <MenuItem key={kdnr} value={kdnr}>
                        <Checkbox checked={selectedValues.indexOf(kdnr) > -1} />
                        <ListItemText primary={kdnr} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

import { BasicPage } from "../components/BasicPage";
import Person from "@mui/icons-material/Person";
import { apiFetch, useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link as Link2, Navigate } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Link, ButtonGroup, Alert, Stack,
    Grid, Box, Button, FormControl,
    FormControlLabel, Checkbox,
    TextField, Typography, InputLabel, Select, MenuItem
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FadingAlert } from "../components/FormFields";

import { useParams } from 'react-router';



export const UserPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();


    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();


    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [accountid, setAccountID] = useState("");
    const [password, setPassword] = useState("");
    const [company, setCompany] = useState("");
    const [tenant, setTenant] = useState("");
    const [salutation, setSalutation] = useState("");
    const { token, claims } = useAuth();

    const onSubmit = data => {
        console.log("Submit with:", data);
        reset();
    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();

        // const data = new FormData(event.currentTarget);
        setError("");

        var methodType, _id;

        if (id === "new") {
            methodType = "POST";
            _id = "";

        } else {
            methodType = "PATCH";
            _id = "/" + id;
        }

        const requestOptions = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                "displayName": displayName,
                "email": email,
                "password": password,
                "role": role,
                "accountid": accountid,
                "company": company,
                "tenant": tenant,
                "salutation": salutation
            })
        };
        console.log(requestOptions);

        apiFetch('/app/users' + _id, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.message) {
                    setError(data.message);
                } else {
                    setSuccess("Profil erfolgreich gespeichert.");
                }
                // navigate("/dashboard/user");
            })
            .catch(error => {
                console.log(error);
                setError(error.message);
            });

    };


    const [loading, setLoading] = useState(false)
    const [data, setData] = useState("");


    useEffect(() => {

        if (id === "new") return;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        };

        apiFetch('/app/users/' + id, requestOptions)
            .then(response => response.json())
            .then(userById => {
                console.log(userById);
                setDisplayName(userById.displayName)
                setEmail(userById.email)
                setRole(userById?.customClaims?.role)
                setAccountID(userById?.customClaims?.aid)
                setPassword("");
                setCompany(userById?.customClaims?.cny);
                setTenant(userById?.customClaims?.tnt);
                setSalutation(userById?.customClaims?.son)
            });

    }, [error]);

    return (

        <>
            < BasicPage title={id === "new" ? "Profil anlegen" : "Profil ändern"} icon={< Person />} >

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box component="form" onSubmit={handleSubmit2}
                        // minWidth={'600px'}
                        sx={{
                            mt: 3,
                            width: '100%', // Fix IE 11 issue.
                            maxWidth: '600px',
                        }}>
                        {/* <form onSubmit={handleSubmit(onSubmit)}> */}

                        <Stack spacing={2}>

                            <TextField
                                name="tenant"
                                required
                                fullWidth
                                id="tenant"
                                label="Mandant"
                                value={tenant}
                                onChange={(e) => setTenant(e.target.value)}
                            // autoFocus
                            />

                            <TextField
                                name="company"
                                required
                                fullWidth
                                id="company"
                                label="Institution / Firma"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                            // autoFocus
                            />

                            <FormControl>
                                <InputLabel id="salutation-label">Anrede</InputLabel>
                                <Select
                                    labelId="salutation-label"
                                    id="salutation"
                                    value={salutation}
                                    label="Anrede"
                                    onChange={(e) => setSalutation(e.target.value)}
                                >
                                    <MenuItem value="Herr">Herr</MenuItem>
                                    <MenuItem value="Frau">Frau</MenuItem>
                                    <MenuItem value="Dr.">Dr.</MenuItem>
                                    <MenuItem value="Prof.">Prof.</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                name="displayName"
                                required
                                fullWidth
                                id="displayName"
                                label="Vor- und Nachname"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}

                            />


                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                            />

                            <TextField

                                fullWidth
                                name="password"
                                label="Passwort"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                            />

                            <TextField
                                required
                                fullWidth
                                id="accountid"
                                label="Kunden-Nr."
                                name="accountid"
                                value={accountid}
                                onChange={(e) => setAccountID(e.target.value)}
                                autoComplete=""
                            />


                            <FormControl fullWidth>
                                <InputLabel id="userrole-select-label">Benutzerrechte</InputLabel>
                                <Select
                                    labelId="userrole-select-label"
                                    id="role"
                                    name="role"
                                    value={role}
                                    label="Benutzerrechte"
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>

                                    <MenuItem value="Admin">Admin</MenuItem>
                                    <MenuItem value="User">User</MenuItem>
                                </Select>
                            </FormControl>
                            {error && <Alert severity="error">{error}</Alert>}
                            {success && <Alert severity="success">{success}</Alert>}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                {id === "new" ? "Anlegen" : "Ändern"}
                            </Button>

                            <Button
                                onClick={() => navigate("/web/user")}
                            >   Zurück
                            </Button>

                        </Stack>

                    </Box>
                </Grid>

            </BasicPage>

        </>
    );
};


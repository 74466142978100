import { BasicPage } from "../components/BasicPage";
import React, { Component, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {
    FormContainer, TextFieldElement, PasswordRepeatElement,
    CheckboxElement, PasswordElement, SelectElement
} from 'react-hook-form-mui'

import Home from "@mui/icons-material/Home";
import NavAbonnement from "../components/NavAbonnement";
import { Routes, Route, useLocation } from "react-router-dom";

import {
    TextField, Typography, Stack, FormControl, FormLabel, RadioGroup,
    FormHelperText, FormControlLabel, Radio, Checkbox
} from "@mui/material/";

import { sendEmailTemplate } from "../firebase";

import { DataGrid, GridRowsProp, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useAuth } from "../hooks/useAuth";

import { ButtonSubmit as AboButtonSubmit, FieldMessage as AboFieldMessage } from "../components/FormFields";
import { AboList } from "../components/AboList";

import SearchMagazines from "./SearchMagazines"
import { ImportContacts } from "@mui/icons-material";


// import {Table} from "react-bootstrap";

function AboStatus() {


    useEffect(() => {


    }, []);


    return (
        <AboList></AboList>

    )
}


const AboHeaderFields = () => {

    return (
        <>
            <TextFieldElement
                label={'Titel / Art.-Nr.'}
                name={'titel'}
            />
            <TextFieldElement
                label={'Verlag'}
                name={'verlag'}
            />
        </>
    )
}




const AboChange = ({ onSubmit }) => {

    return (

        <FormContainer
            onSuccess={onSubmit}
            onSubmit={onSubmit}
            FormProps={{
                'aria-autocomplete': 'none',
                autoComplete: 'new-password'
            }}>
            <Stack spacing={2}>
                <Typography component="h2" variant="h5">
                    Zeitschriften-Verteiler bearbeiten
                </Typography>
                <b>Bitte ausfüllen, soweit Daten bekannt</b>

                <AboHeaderFields />

                <Stack direction="row" spacing={2}>

                    <TextFieldElement
                        label={'Leser (Name)'}
                        name={'bezieher_hinzu'}
                        defaultValue=""
                    />

                    <TextFieldElement
                        label={'Kst. Stelle'}
                        name={'bezieher_hinzu_ks'}
                    />

                </Stack>

                <TextFieldElement
                    label={'Wo im Vert. einfügen?'}
                    name={'bezieher_an_position'}
                />

                <TextFieldElement
                    label={'Wo im Vert. einfügen?'}
                    name={'bezieher_an_position'}
                />

                <b>Vom Verteiler entfernen</b>
                <Stack direction="row" spacing={2}>

                    <TextFieldElement
                        label={'Leser (Name)'}
                        name={'bezieher_entf'}
                    />

                    <TextFieldElement
                        label={'Kst. Stelle'}
                        name={'bezieher_entf_kst'}
                    />
                </Stack>

                <b>Kostenstelle ändern</b>
                <Stack direction="row" spacing={2}>
                    <TextFieldElement
                        label={'Leser (Name)'}
                        name={'bezieher_kst'}
                    />

                    <TextFieldElement
                        label={'Kst. Stelle alt'}
                        name={'bezieher_kst_alt'}
                    />

                    <TextFieldElement
                        label={'Kst. Stelle neu'}
                        name={'bezieher_kst_neu'}
                    />
                </Stack>

                <AboButtonSubmit />

            </Stack >
        </FormContainer>

    )

};

const AboVerteiler = ({ onSubmit }) => {

    return (

        <FormContainer
            onSuccess={onSubmit}
            onSubmit={onSubmit}
            FormProps={{
                'aria-autocomplete': 'none',
                autoComplete: 'new-password'
            }}>
            <Stack spacing={2}>

                <Typography component="h2" variant="h5">
                    Verteiler
                </Typography>
                <Typography component="h3" variant="h6">
                    Primärbezieher
                </Typography>


                <Stack direction="row" spacing={2}>
                    <TextFieldElement
                        label={'Kostenstelle'}
                        name={'PrimaerbezieherOENummer'}
                    />
                    <TextFieldElement
                        label={'Herr / Frau'}
                        name={'Primaerbezieher_Name'}
                    />
                </Stack>

                <h3>Weitere Bezieher</h3>
                <Stack direction="row" spacing={2}>

                    <TextFieldElement
                        label={'Kostenstelle'}
                        name={'Bezieher1OE'}
                    />

                    <TextFieldElement
                        label={'Herr / Frau'}
                        name={'Bezieher1Name'}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>

                    <TextFieldElement
                        label={'Kostenstelle'}
                        name={'Bezieher2OE'}
                    />

                    <TextFieldElement
                        label={'Herr / Frau'}
                        name={'Bezieher2Name'}
                    />
                </Stack>

                <Stack direction="row" spacing={2}>

                    <TextFieldElement
                        label={'Kostenstelle'}
                        name={'Bezieher3OE'}
                    />

                    <TextFieldElement
                        label={'Herr / Frau'}
                        name={'Bezieher3Name'}
                    />
                </Stack>
            </Stack>
        </FormContainer>
    )
};




const AboDelete = ({ onSubmit }) => {

    return (

        <FormContainer
            onSuccess={onSubmit}
            onSubmit={onSubmit}
            FormProps={{
                'aria-autocomplete': 'none',
                autoComplete: 'new-password'
            }}>
            <Stack spacing={2}>
                <Typography component="h2" variant="h5">
                    Zeitschrift abbestellen
                </Typography>

                <b>Bitte ausfüllen, soweit Daten bekannt</b>

                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Lieferende mit:</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="AbAusgabe"
                    // value={value}
                    // onChange={handleChange}
                    >
                        <FormControlLabel value="
der nächstmöglichen Ausgabe (Ende der regulären Laufzeit)" control={<Radio />} label="
der nächstmöglichen Ausgabe (Ende der regulären Laufzeit)" />
                        <FormControlLabel value="
Letzte Lieferausgabe" control={<Radio />} label="
Letzte Lieferausgabe" />
                    </RadioGroup>
                    <TextFieldElement

                        label={'letzte Ausgabe'}
                        name={'letzte Ausgabe'}
                    />
                </FormControl>

                <AboVerteiler />

                <TextFieldElement
                    label={'Freitext'}
                    name={'Freitext'}
                    placeholder="Ihre Nachricht"
                    multiline
                    rows={4}
                    maxRows={10}
                />


                <AboButtonSubmit />

            </Stack>
        </FormContainer>

    )
}

const AboRequest = ({ onSubmit }) => {
    return (

        <FormContainer
            onSuccess={onSubmit}
            onSubmit={onSubmit}
            FormProps={{
                'aria-autocomplete': 'none',
                autoComplete: 'new-password'
            }}>
            <Stack spacing={2}>
                <Typography component="h2" variant="h5">
                    Zeitschrift anfragen
                </Typography>
                <b>Bitte ausfüllen, soweit Daten bekannt</b>

                <AboHeaderFields />

                <SelectElement
                    label="Bezugsform (innerhalb Europas)"
                    name="Bezugsform (innerhalb Europas)"
                    options={[
                        {
                            id: 'gedruckt / print',
                            label: 'gedruckt / print'
                        },
                        {
                            id: 'online / electronic',
                            label: 'online / electronic'
                        },
                        {
                            id: 'gedruckt + online',
                            label: 'gedruckt + online'
                        }
                    ]}
                />

                <b>Wenn Bezugsform 'online'</b>

                <TextFieldElement
                    label={'Anzahl User'}
                    name={'anzahl_user'}
                />

                <FormControlLabel control={<Checkbox name="" value="Verfügbarkeit in Olinemodulen" />} label="Verfügbarkeit in Onlinemodulen (z.B. Beck Online) prüfen ?" />

                <AboFieldMessage />

                <AboButtonSubmit />


            </Stack>
        </FormContainer>
    )
}



const AboOrder = ({ onSubmit }) => {
    const [checked, setChecked] = useState();
    const [checked2, setChecked2] = useState();

    return (

        <FormContainer
            onSuccess={onSubmit}
            onSubmit={onSubmit}
            FormProps={{
                'aria-autocomplete': 'none',
                autoComplete: 'new-password'
            }}>
            <Stack spacing={2}>
                <Typography component="h2" variant="h5">
                    Zeitschrift bestellen
                </Typography>

                <b>Bitte ausfüllen, soweit Daten bekannt</b>

                <AboHeaderFields />

                <TextField
                    label={'Menge'}
                    name={'bestellmenge'}
                />

                {/* <FormControlLabel
                control={
                    <Radio
                        checked={checked}
                        onClick={() => setChecked(!checked)}
                        value="other"
                        color="primary"
                        label="Bezugsform (innerhalb Europas)"
                    />

                }
                label={
                    checked ? (

                        <FormControl fullWidth>
                            <InputLabel id="select-bezugsform">Bezugsform (innerhalb Europas)</InputLabel>
                            <SelectElement
                                labelId="select-bezugsform"
                                id="select-bezugsform"
                                // value=""
                                label="Bezugsform (innerhalb Europas)"
                                name="Bezugsform"
                            // onChange={handleChange}
                            >
                                <MenuItem value="edruckt / print">gedruckt/print</MenuItem>
                                <MenuItem value="online / electronic">online/electronic</MenuItem>
                                <MenuItem value="gedruckt + online">gedruckt + online</MenuItem>
                            </SelectElement>
                        </FormControl>

                    ) : (
                        "Bezugsform (innerhalb Europas)"
                    )
                }
            />

            <FormControlLabel
                control={
                    <Radio
                        checked={checked2}
                        onClick={() => setChecked2(!checked2)}
                        value="other"
                        color="primary"
                        label="Bezugsform (außerhalb Europas)"
                    />

                }
                label={
                    checked2 ? (


                        <FormControl fullWidth
                        >
                            <InputLabel id="select-bezugsform-ausland-label">Bezugsform (außerhalb Europas)</InputLabel>
                            <SelectElement
                                labelId="select-bezugsform-ausland-label"
                                id="select-bezugsform-ausland"
                                // value=""
                                label="Bezugsform (außerhalb Europas)"
                                name="Bezugsform"

                            // onChange={handleChange}
                            >
                                <MenuItem value="Standard">Standard</MenuItem>
                                <MenuItem value="airmail / airborne / DHL / Luftpost(z.B.aus USA ~1 Woche)">airmail/airborne/DHL/Luftpost (z.B. aus USA ~1 Woche)</MenuItem>
                                <MenuItem value="airfreight / airlifted / expedited / SAL / (z.B.aus USA ~2-4 Wochen" >airfreight/airlifted/expedited/SAL/ (z.B. aus USA ~2-4 Wochen)</MenuItem>
                                <MenuItem value="surface / ship(z.B.aus USA ~4 - 12 Wochen)">surface/ship (z.B. aus USA ~4-12 Wochen)</MenuItem>
                            </SelectElement>
                            <FormHelperText>Versand (außerhalb Europas, sofern vom Verlag angeboten und nicht bereits inklusive)</FormHelperText>
                        </FormControl>

                    ) : (
                        "Bezugsform (außerhalb Europas)"
                    )
                }
            /> */}

                <SelectElement
                    label="Bezugsform (innerhalb Europas)"
                    name="Bezugsform (innerhalb Europas)"
                    options={[
                        {
                            id: 'gedruckt / print',
                            label: 'gedruckt / print'
                        },
                        {
                            id: 'online / electronic',
                            label: 'online / electronic'
                        },
                        {
                            id: 'gedruckt + online',
                            label: 'gedruckt + online'
                        }
                    ]}
                />


                <FormControl fullWidth>
                    <SelectElement
                        label="Bezugsform (außerhalb Europas)"
                        name="Bezugsform (außerhalb Europas)"
                        options={[
                            {
                                id: 'Standard',
                                label: 'Standard'
                            },
                            {
                                id: 'airmail / airborne / DHL / Luftpost(z.B.aus USA ~1 Woche)',
                                label: 'airmail / airborne / DHL / Luftpost(z.B.aus USA ~1 Woche)'
                            },
                            {
                                id: 'airfreight / airlifted / expedited / SAL / (z.B.aus USA ~2-4 Wochen',
                                label: 'airfreight / airlifted / expedited / SAL / (z.B.aus USA ~2-4 Wochen'
                            },

                            {
                                id: 'surface/ship (z.B. aus USA ~4-12 Wochen)',
                                label: 'surface/ship (z.B. aus USA ~4-12 Wochen)'
                            }
                        ]}
                    />


                    <FormHelperText>Versand (außerhalb Europas, sofern vom Verlag angeboten und nicht bereits inklusive)</FormHelperText>
                </FormControl>


                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Lieferbeginn mit</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="AbAusgabe"
                    // value={value}
                    // onChange={handleChange}
                    >
                        <FormControlLabel value="nächstmöglichen Ausgabe" control={<Radio />} label="nächstmöglicheN Ausgabe" />
                        <FormControlLabel value="Ab der Ausgabe" control={<Radio />} label="Ab der Ausgabe" />
                    </RadioGroup>
                    <TextField

                        label={'Ab Ausgabe'}
                        name={'AbAusgabeDatum'}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Laufzeit</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="AbAusgabe"
                    // value={value}
                    // onChange={handleChange}
                    >
                        <FormControlLabel value="unbeschränkt bis auf Widerruf" control={<Radio />} label="unbeschränkt bis auf Widerruf" />
                        <FormControlLabel value="Beschränkt auf Jahre = " control={<Radio />} label="Beschränkt auf Jahre = " />
                    </RadioGroup>
                    <TextField

                        label={'beschränkt auf'}
                        name={'beschraenkt_auf'}
                    />
                </FormControl>

                <AboVerteiler />

                <AboFieldMessage />

                <AboButtonSubmit />
            </Stack >
        </FormContainer>

    )
};

const AboContact = ({ onSubmit }) => {

}


export const Abonnements = () => {
    const location = useLocation();
    console.log(location);
    const { user, claims } = useAuth();


    const onSubmit = (formData) => {

        var data = {};
        data.form = {};
        data.user = {};
        Object.assign(data.form, formData);
        Object.assign(data.user, claims);

        sendEmailTemplate(data);
        //setValues(formData);
    }

    return (

        <BasicPage title="Zeitschriften &amp; Fortsetzungen" icon={<ImportContacts />} >

            <Routes>
                <Route
                    exact
                    path="abostatus"
                    element={<AboStatus />}
                />
                <Route
                    exact
                    path="abosearch"
                    element={<SearchMagazines />}
                />
                <Route
                    exact
                    path="aborequest"
                    element={
                        <AboRequest onSubmit={onSubmit} />
                    }
                />
                <Route
                    exact
                    path="aboorder"
                    element={
                        <AboOrder onSubmit={onSubmit} />
                    }
                />
                <Route
                    path="abochange"
                    element={
                        <AboChange onSubmit={onSubmit} />
                    }
                />
                <Route
                    path="abodelete"
                    element={
                        <AboDelete onSubmit={onSubmit} />
                    }
                />
                <Route
                    path="abocontact"
                    element={
                        <AboContact onSubmit={onSubmit} />
                    }
                />

            </Routes>
        </BasicPage>

    )
};
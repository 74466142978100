import React, { useState, useEffect } from 'react'
import { BasicPage } from "../components/BasicPage";
import Person from "@mui/icons-material/Person";
import { useAuth } from "../hooks/useAuth";
import {
    Box, Grid, Stack, Typography, Button,
    TextField, Alert
} from "@mui/material";
import {
    FormContainer, TextFieldElement, PasswordRepeatElement,
    CheckboxElement, PasswordElement
} from 'react-hook-form-mui'

import {

} from "firebase/auth";

import { AcUnitOutlined } from '@mui/icons-material';
import { FadingAlert } from '../components/FormFields';

import { auth, translateFirebaseError } from "../firebase";


export const ProfilePage = () => {
    const { user, change, claims, accountID } = useAuth();
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState("");
    const [data, setData] = useState("");
    const [deleted, setDeleted] = useState(false);

    const token = useAuth().token; // funktioniert nur so

    const handleSubmit = async (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        console.log(data.get("password"))

        setError("");
        try {
            await change(data.get("password"));
            setMessage("Passwort wurde geändert");
            // navigate("/dashboard/profile", { replace: true });
        } catch (err) {
            console.log("Fehler", err.code);
            setError(translateFirebaseError(err));
        }
    };


    return (
        <>
            < BasicPage title="Profil" icon={< Person />} >
                {message && <Alert severity="success">{message}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
                <Stack spacing={2} width="500px" margin="0 auto">
                    <Typography>
                        Mandant: {claims?.tnt}<br />
                        Firma / Institution: {claims?.cny}<br />
                        Anrede: {claims?.son}<br />
                        Name: {claims?.name}<br />
                        Email: {claims?.email}<br />
                        Telefon: {claims?.phone}<br />
                        Role: {claims?.role}<br />
                        Kunden-Nr.: {claims?.aid} ({Array.isArray(accountID) ? accountID.join(", ") : ""})<br />
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Neues Passwort"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Passwort ändern
                            </Button>
                        </Grid>

                    </Box>
                    <div>
                        {/*
                        <button onClick={handleDownloadClick}>Download file</button>
                     {file && (
                            <a href={file.url} download={file.filename}>
                                {file.filename}
                            </a>
                        )} */}
                    </div>

                    <Typography>

                    </Typography>

                </Stack>


            </BasicPage >

        </>
    );
};



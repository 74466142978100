import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updatePassword
} from "firebase/auth";

import { auth, firebaseConfig } from "../firebase";
import { useErrorBoundary } from "react-error-boundary";

const AuthContext = createContext();

export function AuthProvider({ children, userData }) {
  const [user, setUser] = useState({});
  const [token, setToken] = useState('')
  const [claims, setClaims] = useState('')
  const [accountID, setAccountID] = useState([])



  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function change(newPassword) {
    return updatePassword(auth.currentUser, newPassword)
  }

  function changeAccountID(newAccountID) {
    setAccountID(newAccountID)
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (_user) => {
      // console.log("Auth", _user);

      setUser(_user);
      let sessionTimeout = null;

      if (_user) {
        console.log("Auth - onAuthStateChanged", _user);

        _user.getIdToken(true) // 1
          .then(latestToken => {
            setToken(latestToken)
          })
          .catch(err => console.log(err))

        _user.getIdTokenResult(true) // 1
          .then((idTokenResult) => {
            setClaims(idTokenResult.claims);
            setAccountID(idTokenResult.claims.aid.split(","));

            // firebase logout after 1 hour inactivity

            const sessionDuration = 1000 * 60 * 60; // 1 hour in milliseconds
            let sessionTimer = null;

            const resetSessionTimer = () => {
              clearTimeout(sessionTimer);
              sessionTimer = setTimeout(() => {
                auth.signOut().then(() => {
                  console.log("User signed out due to inactivity");
                }).catch((error) => {
                  console.log(error);
                });
              }, sessionDuration);
            }

            resetSessionTimer();

            document.addEventListener("mousemove", resetSessionTimer);
            document.addEventListener("keypress", resetSessionTimer);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
      }

    });

    return () => { unsubscribe(); };
    //}, [auth]);
    // chceck if userData is not empty
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{ user, token, claims, accountID, login, signup, logout, change, changeAccountID }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};



export function apiFetch(path, options = {}) {
  var url = "https://europe-west1-" + firebaseConfig.projectId + ".cloudfunctions.net" + path;

  if (firebaseConfig.projectId === "litport-heine-c2471") {
    url = "https://europe-west3-" + firebaseConfig.projectId + ".cloudfunctions.net" + path;
  }

  const headers = options.headers || {};
  const accessToken = auth.currentUser && auth.currentUser.accessToken;

  if (accessToken) {
    headers['Authorization'] = "Bearer " + accessToken;
    headers['Content-Type'] = "application/json";
  }
  else {
    // throw new Error('Es wurde kein gültiger Token gefunden.');
  }
  console.log({ ...options, headers });
  return fetch(url, { ...options, headers });
}




import { BasicPage } from "../components/BasicPage";
import { useAuth, apiFetch } from "../hooks/useAuth";
import BarChart from "@mui/icons-material/BarChart";
import { createContext, useContext, useEffect, useState } from "react";

import {
    Card, Typography, CardContent, FormControl, FormLabel, RadioGroup, Grid, Box, Alert,
    LinearProgress
} from "@mui/material/";
import { Stack } from "@mui/system";

import SearchNewbooks from "./SearchNewbooks";


export const WelcomePage = () => {
    const { user, change, claims, accountID } = useAuth();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [data, setData] = useState('');
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);


    const token = useAuth().token; // funktioniert nur so

    const numberFormat = (value) =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

    useEffect(() => {
        setError("");
        setMessage("");

        if (!accountID) {
            setError("Es wurde keine AccountID ausgewaehlt");
            //return;
        }

        if (!token) {
            setError("Es gibt keinen definierten User Token");
            return
        }

        setLoading(true);


        // apiFetch("/app/stats/" + accountID)
        //     .then(response => response.json())
        //     .then(data => {
        //         setLoading(false);
        //         console.log(data[0]);
        //         setData(data[0])
        //         if (data.message)
        //             setMessage(data.message);
        //     })
        //     .catch((err) => {
        //         throw (err.message);
        //     });

        // apiFetch('/app/users/', {
        //     method: "POST",
        //     body: JSON.stringify({
        //         "displayName": "Ralph Keser",
        //         "email": "superadmin5@ataraxie.de",
        //         "password": "123456",
        //         "role": "superadmin",
        //         "accountid": "12345",
        //         "company": "Ataraxie GmbH",
        //         "tenant": "Schöning",
        //         "salutation": "Herr",

        //     })
        // })


    }, [accountID,]);

    return (

        <BasicPage title="Willkommen im Litport v2" icon={<BarChart />} >

            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                mt={6}
            >
                {loading && false &&
                    <Box
                        mt={2}
                        mb={2}
                        sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
                < Grid item xs={12} >
                    {/* {!data && !loading && <Alert severity="info">Es sind keine Daten vorhanden.</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                    {success && <Alert severity="success">{success}</Alert>} */}

                    {data && false &&
                        <>
                            <Typography variant="h6" component="h1" gutterBottom sx={{ textAlign: "center" }}>
                                Statistik 2023
                            </Typography>

                            <Box sx={{
                                display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: 3,
                                rowGap: 1,
                                mt: 2,
                            }}>

                                {/* <div>{JSON.stringify(data, null, 2)}</div> */}
                                <Card sx={{ textAlign: "center" }}>
                                    <CardContent>
                                        <Typography color="text.secondary" gutterBottom>
                                            Käufe
                                        </Typography>

                                        <Typography variant="h4">
                                            {data.GesamtBestellungen}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <Card sx={{ textAlign: "center" }}>
                                    <CardContent>
                                        <Typography color="text.secondary" gutterBottom>
                                            Umsatz
                                        </Typography>

                                        <Typography variant="h4">
                                            {numberFormat(data.GesamtKosten)}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <Card sx={{ textAlign: "center" }}>
                                    <CardContent>
                                        <Typography color="text.secondary" gutterBottom>
                                            neue Abos
                                        </Typography>

                                        <Typography variant="h4">
                                            {data.Abos}
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </Box>
                        </>
                    }

                </Grid >



            </Grid>
            {/*
            <Grid>
                <Typography variant="h6" mt={6} mb={2}>Empfehlungen</Typography>
            </Grid>

            <SearchNewbooks></SearchNewbooks> */}

        </BasicPage >


    )

};

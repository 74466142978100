import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../firebase";
import { ResultViewElastic } from "../components/ResultView";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import Select, { components } from "react-select";

import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch,
} from "@elastic/react-search-ui";

import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
    buildAutocompleteQueryConfig,
    buildFacetConfigFromConfig,
    buildSearchOptionsFromConfig,
    buildSortOptionsFromConfig,
    getConfig,
    getFacetFields,
    stripUnnecessaryResultFields
} from "../config/configHelperMeta";

import {
    Container, Grid, Box, Typography, TextField, LinearProgress, Button
} from '@mui/material/';

import SearchIcon from '@mui/icons-material/Search';
import { BasicPage } from "../components/BasicPage";
import { addFilter } from "@elastic/search-ui/lib/esm/actions";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const connector = new AppSearchAPIConnector({
    searchKey,
    engineName,
    hostIdentifier,
    endpointBase,
    beforeSearchCall: (options, next) =>
        // works but facet is not set
        next({
            ...options,

            //     filters: { 'any': [{ "engine": "libri" }] }
        })
});

const config = {
    debug: false,
    searchQuery: {

        disjunctiveFacets: ["engine", "productform", "language", "publisher", "publishingdate"],

        facets: buildFacetConfigFromConfig(),
        ...buildSearchOptionsFromConfig(),
        // filters: [{ field: 'engine', values: ["libri"], type: "any" }] 

    },
    autocompleteQuery: buildAutocompleteQueryConfig(),
    apiConnector: connector,
    alwaysSearchOnInitialLoad: false,
    //initialState: { searchTerm: "test", resultsPerPage: 40,}
};


export default function SearchMeta({ handleClick }) {
    const [user, loading, error] = useAuthState(auth);
    const facetDisplayNameMap = {
        "vlb": "VLB",
        "magazines": "Zeitschriften",
        "libri": "Libri",
        "newbooks": "Newbooks",
    }


    return (
        <>
            <BasicPage title="Meta-Suche" icon={<SearchIcon />}>
                <SearchProvider config={config}>

                    <WithSearch
                        mapContextToProps={({ wasSearched, isLoading, addFilter, filters, removeFilter }) => ({ wasSearched, isLoading, addFilter, filters, removeFilter })}
                    >

                        {/* {({ wasSearched, isLoading }) => { */}
                        {({ wasSearched, isLoading, filters, addFilter }) => {

                            console.log("filters", filters);
                            if (wasSearched && filters.length == 0) {
                                addFilter("engine", "vlb", "any");
                            }

                            return (
                                <>

                                    <ErrorBoundary>

                                        <Layout
                                            header={
                                                <>

                                                    <SearchBox
                                                        // onSubmit={(searchTerm) => {
                                                        //     alert("You searched for: " + searchTerm)
                                                        // }}

                                                        shouldClearFilters={false}
                                                        autocompleteSuggestions={true}
                                                        inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                                                            <>
                                                                <div className="sui-search-box__wrapper">
                                                                    <TextField
                                                                        label="Suche nach Titel, Autor, ISBN, ..."
                                                                        {...getInputProps({
                                                                            placeholder: "",

                                                                        })}
                                                                    />
                                                                    {getAutocomplete()}
                                                                    <Box sx={{ height: 4 }}>
                                                                        {isLoading && <LinearProgress />}
                                                                    </Box>
                                                                </div>

                                                                {/* <Button
                                                        // {...getButtonProps({
                                                        //     "data-custom-attr": "some value",
                                                        //     "className": "" 
                                                        // })}
                                                        >Suchen</Button> */}
                                                            </>
                                                        )}
                                                    />

                                                </>

                                            }
                                            sideContent={
                                                <>
                                                    {/* {wasSearched && !isLoading && */}
                                                    {wasSearched &&
                                                        <>
                                                            <Sorting
                                                                label={"Sortieren nach"}
                                                                sortOptions={buildSortOptionsFromConfig()}
                                                            />

                                                            {getFacetFields().map(n => (

                                                                <Facet
                                                                    key={n.field}
                                                                    field={n.field}
                                                                    label={n.label}
                                                                    showSearch={n.showSearch}
                                                                    filterType="any"

                                                                    view={({
                                                                        label,
                                                                        onMoreClick,
                                                                        onRemove,
                                                                        onSelect,
                                                                        options,
                                                                        showMore,
                                                                        showSearch,
                                                                        onSearch,
                                                                        searchPlaceholder
                                                                    }) => (
                                                                        <fieldset class="sui-facet">
                                                                            <legend class="sui-facet__title">
                                                                                {label}
                                                                            </legend>

                                                                            {showSearch && (
                                                                                <div class="sui-facet__search">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder={searchPlaceholder}
                                                                                        onChange={e => onSearch(e.target.value)}
                                                                                    />
                                                                                </div>)
                                                                            }

                                                                            <div class="sui-multi-checkbox-facet">

                                                                                {options.filter(option => option.value !== "false" && option.value !== "" && option.value !== "keine Angabe").map(option => (

                                                                                    <label
                                                                                        key={`${(option.value)}`}
                                                                                        htmlFor={`example_facet_${label}${(
                                                                                            option.value
                                                                                        )}`}
                                                                                        className="sui-multi-checkbox-facet__option-label"
                                                                                    >
                                                                                        <div class="sui-multi-checkbox-facet__option-input-wrapper">
                                                                                            <input
                                                                                                data-transaction-name={`facet - ${label}`}
                                                                                                id={`example_facet_${label}${(
                                                                                                    option.value
                                                                                                )}`}
                                                                                                type="checkbox"
                                                                                                className="sui-multi-checkbox-facet__checkbox"
                                                                                                checked={option.selected}
                                                                                                onChange={() => (option.selected ? onRemove(option.value) : onSelect(option.value))}
                                                                                            /><span className="sui-multi-checkbox-facet__input-text">{facetDisplayNameMap[option.value] ? facetDisplayNameMap[option.value] : option.value}</span>
                                                                                        </div><span className="sui-multi-checkbox-facet__option-count">{option.count.toLocaleString("en")}
                                                                                        </span>

                                                                                    </label>

                                                                                ))}
                                                                            </div>
                                                                            {showMore && (
                                                                                <button
                                                                                    type="button"
                                                                                    className="sui-facet-view-more"
                                                                                    onClick={onMoreClick}
                                                                                    aria-label="Show more options"
                                                                                >
                                                                                    + mehr
                                                                                </button>
                                                                            )}
                                                                        </fieldset>
                                                                    )}
                                                                />
                                                            ))}
                                                        </>
                                                    }
                                                </>
                                            }

                                            bodyContent={
                                                <>
                                                    {!isLoading &&
                                                        <Results
                                                            titleField={getConfig().titleField}
                                                            urlField={getConfig().urlField}
                                                            thumbnailField={getConfig().thumbnailField}
                                                            shouldTrackClickThrough={true}
                                                            resultView={ResultViewElastic}
                                                        />}
                                                </>
                                            }

                                            bodyHeader={
                                                <React.Fragment>

                                                    {wasSearched && !isLoading &&
                                                        <PagingInfo

                                                            view={({
                                                                className,
                                                                end,
                                                                searchTerm,
                                                                start,
                                                                totalResults,
                                                                ...rest
                                                            }) => (
                                                                <div className={className} {...rest}>
                                                                    Ergebnisse {" "}
                                                                    <strong>
                                                                        {start} - {end}
                                                                    </strong>{" "}
                                                                    von <strong>{totalResults}</strong>
                                                                    {searchTerm && (
                                                                        <>
                                                                            {" "}
                                                                            für: <em>{searchTerm}</em>
                                                                        </>
                                                                    )}
                                                                </div>

                                                            )}

                                                        />}
                                                    {wasSearched && !isLoading &&
                                                        <ResultsPerPage


                                                            viewx={({
                                                                className,
                                                                onChange,
                                                                options,
                                                                selectedOption,
                                                                wrapOption,
                                                                selectedValue,
                                                                ...rest
                                                            }) => (

                                                                <div className={className} {...rest}>
                                                                    <label htmlFor="results-per-page">Ergebnisse pro Seite</label>
                                                                    <select

                                                                        id="results-per-page"
                                                                        onChange={e => onChange(e.target.value)}
                                                                        value={selectedValue}
                                                                    >
                                                                        {options.map(option => (
                                                                            <option
                                                                                key={option}
                                                                                value={option}
                                                                            >
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>


                                                            )}

                                                        />}
                                                </React.Fragment>
                                            }

                                            bodyFooter={
                                                <>
                                                    {!isLoading && <Paging />}
                                                </>
                                            }
                                        />
                                    </ErrorBoundary>
                                </>
                            );
                        }}
                    </WithSearch>
                </SearchProvider>
            </BasicPage >

        </>
    );
}

import React, { Component, useState, useEffect } from 'react'

import { BasicPage } from '../components/BasicPage';
import { OrderList } from '../components/OrderList';

// import {Table} from "react-bootstrap";

import Inventory from "@mui/icons-material/Inventory";

export default function ApiContainer() {
    const [loading, setLoading] = useState(false)
    const [fromFetch, setFromFetch] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [axiosData, setAxiosData] = useState(null);
    const [data, setData] = useState("");


    return (
        <BasicPage title="Einzelbestellungen" icon={<Inventory />}>

            <OrderList></OrderList>

        </BasicPage>
    )
}
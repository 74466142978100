import React, { Component, useState, useEffect } from 'react'
import axios from 'axios';
import { useAuth } from "../hooks/useAuth";

import {
    Container, Grid, Box, Typography, TextField, LinearProgress
} from '@mui/material/';

import PaginationApi from '../components/PaginationApi';
import { BasicPage } from '../components/BasicPage';
import { ResultViewApiVLB } from '../components/ResultView';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchVlbApi() {

    const [axiosData, setAxiosData] = useState(null);
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { token } = useAuth();

    const onChangQuery = (newQuery) => {
        setPage(0);
        setQuery(newQuery);
    };

    const onChangePage = (newPage) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (newRowsPerPage) => {
        setPage(0);
        setRowsPerPage(newRowsPerPage);
    };

    const goForAxios = () => {
        setAxiosData(null);
        setLoading(true);

        console.log(query);
        axios.get("https://europe-west1-litport-web.cloudfunctions.net/app/vlb?q=" + query + "&p=" + (page + 1) + "&c=" + rowsPerPage, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,

            },
        }).then(response => {
            console.log('getting data from axios', response.data);
            setLoading(false);
            setAxiosData(response.data);

        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {

        if (query.length > 2) {
            const timeOutId = setTimeout(() => goForAxios(), 500);
            return () => clearTimeout(timeOutId);
        } else {
            setAxiosData(null);
        }

    }, [query, page, rowsPerPage]);

    return (
        <BasicPage title="API Suche VLB" icon={<SearchIcon />} >
            <Container>

                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        '& .markdown': {
                            py: 3,
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', pt: 5, pb: 2 }}>

                        <TextField id="search"
                            label="Profisuche nach Stichwort, ISBN, Autor und Verlag"
                            fullWidth
                            onChange={(event) => onChangQuery(event.target.value)}
                        />

                    </Box>
                    {loading &&
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    }

                </Grid>

                <Grid container sx={{ pt: 2 }}>

                    {axiosData &&
                        <>
                            <Grid item xs={6} sx={{ p: 2 }}>
                                <Typography sx={{}}>
                                    {axiosData?.totalElements} Ergebnisse
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <PaginationApi
                                    page={page}
                                    // count={axiosData.totalPages}
                                    onChangePage={onChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                />
                            </Grid>


                        </>
                    }



                </Grid>

                {axiosData &&
                    axiosData.content.map((item, index) => {
                        return (
                            <ResultViewApiVLB key={index} item={item} />
                        );
                    })}

            </Container >
        </BasicPage >

    )
}